import "./greatHall.css";

import React from "react";
import { Helmet } from "react-helmet";

import { ENTRY_POINT } from "../../utils/stateTypes";
import { getEntryPoint } from "../../utils/entryStorage";
import Header from "../sections/Header";
import LineSeparator from "../sections/LineSeparator";
import GreenBlockSection from "../sections/GreenBlockSection";
import BlockHeading from "../sections/BlockHeading";
import CoverFlowGallery from "../sections/CoverFlowGallery";
import VideoSection from "../sections/VideoSection";
import Contact from "../sections/Contact";
import specialsData from "../home/specialsData";
import Specials from "../sections/Specials";

const GALLERY_IMAGES_1 = {
  [ENTRY_POINT.DEFAULT]: [
    { src: "/images/block-gallery-1/SG-img-(153).jpg", alt: "A bride-to-be laughing as she prepares at The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(152).jpg", alt: "A group of groomsmen smoking a cigar together at The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(79).jpg", alt: "A bride holding red flowers in her dress at The Great Hall" },
    { src: "/images/block-gallery-1/C_T-26(1).jpg", alt: "A bride standing on the balcony of the bridal room at The Great Hall" },
    { src: "/images/block-gallery-1/C_T-37(1).jpg", alt: "A chandelier hanging in the bridal room at The Great Hall" },
    { src: "/images/block-gallery-1/g173.jpg", alt: "A bride in the bath at The Great Hall" },
  ],
  [ENTRY_POINT.CORPORATE]: [
    { src: "/images/corporate/Corporate_2.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_3.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_4.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_7.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
    
  ],
  [ENTRY_POINT.BLACK_DIAMOND]: [
    { src: "/images/bdiamond/Bdmnd_6.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/bdiamond/Bdmnd_11.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_20.jpg", alt: "A beautiful event setup at Shepstone Gardens & The Great Hall" },
    { src: "/images/bdiamond/Bdmnd_20.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
  ],
  [ENTRY_POINT.LOVE_IS_LOVE]: [
    { src: "/images/block-gallery-1/SG-img-(153).jpg", alt: "A bride-to-be laughing as she prepares at Shepstone Gardens" },
    { src: "/images/block-gallery-1/SG-img-(152).jpg", alt: "A group of groomsmen smoking a cigar together at Shepstone Gardens" },
    { src: "/images/block-gallery-1/SG-img-(79).jpg", alt: "A bride holding red flowers in her dress at Shepstone Gardens" },
    { src: "/images/block-gallery-1/C_T-26(1).jpg", alt: "A bride standing on the balcony of the bridal room at Shepstone Gardens" },
    { src: "/images/block-gallery-1/C_T-37(1).jpg", alt: "A chandelier hanging in the bridal room at Shepstone Gardens" },
    { src: "/images/block-gallery-1/g173.jpg", alt: "A bride in the bath at Shepstone Gardens" },
  ],
};

const GALLERY_IMAGES_2 = {
  [ENTRY_POINT.DEFAULT]: [
    { src: "/images/block-gallery-1/home_gal777.jpg", alt: "A bride and groom stand in the chapel at The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(100).jpg", alt: "The beautiful ceremony venue at The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(104).jpg", alt: "Stone pillars and open-air balconies at the chapel at The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(103).jpg", alt: "An inside view of the chapel at The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(7).jpg", alt: "Confetti is thrown as the bride and groom walk past at The Great Hall" },
    { src: "/images/block-gallery-1/I_T-6708.jpg", alt: "A father gives away his daughter in marriage The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(173).jpg", alt: "A bride and groom laugh together at the altar at The Great Hall" },
    { src: "/images/block-gallery-1/AlexanderSmith_M&T-395(1).jpg", alt: "A bride and groom together at the altar at The Great Hall" },
  ],
  [ENTRY_POINT.CORPORATE]: [
    { src: "/images/corporate/Corporate_21.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_15.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_19.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_20.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
  ],
  [ENTRY_POINT.BLACK_DIAMOND]: [
    { src: "/images/bdiamond/Bdmnd_16.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/bdiamond/Bdmnd_30.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_12.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
    { src: "/images/bdiamond/Bdmnd_32.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/bdiamond/Bdmnd_15.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_13.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
  ],
  [ENTRY_POINT.LOVE_IS_LOVE]: [
    { src: "/images/loveislove/LoveIsLove_12.jpg", alt: "A same-sex marriage couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/loveislove/LoveIsLove_15.jpg", alt: "A same-sex marriage couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(103).jpg", alt: "An inside view of the chapel at The Great Hall" },
    { src: "/images/loveislove/LoveIsLove_9.jpg", alt: "A same-sex marriage couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(100).jpg", alt: "The beautiful ceremony venue at The Great Hall" },
    { src: "/images/loveislove/LoveIsLove_13.jpg", alt: "A same-sex marriage couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(104).jpg", alt: "Stone pillars and open-air balconies at the chapel at The Great Hall" },  
  ],
};

const GALLERY_IMAGES_3 = {
  [ENTRY_POINT.DEFAULT]: [
    { src: "/images/block-gallery-1/SG-img-(95).jpg", alt: "The beautiul marble floors and chandeliers of The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(98).jpg", alt: "A floral arrangment inside The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(99).jpg", alt: "Minimalist decor and interors of The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(107).jpg", alt: "A bridal couple clapping to speeches inside the banquet hall of The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(131).jpg", alt: "Bride and groom standing at the bottom of the staircase at The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(133).jpg", alt: "A white floral center piece inside The Great Hall" },
    { src: "/images/block-gallery-1/AlexanderSmith_D_G-484.jpg", alt: "The gorgeous golden staircase at The Great Hall" },
    { src: "/images/block-gallery-1/AlexanderSmith_D_G-462.jpg", alt: "A white floral center piece inside The Great Hall" },
    { src: "/images/block-gallery-1/AlexanderSmith-MTt-7(4).jpg", alt: "A bridal couple embrace each other in The Great Hall" },
    { src: "/images/block-gallery-1/AlexanderSmith_D&G-454(1).jpg", alt: "The gorgeous banquet hall and flowers at The Great Hall" },
    { src: "/images/block-gallery-1/AlexanderSmith_M&T-38(2).jpg", alt: "The gorgeous banquet hall and flowers at The Great Hall" },
    { src: "/images/block-gallery-1/AlexanderSmith_M&T-42(1).jpg", alt: "The gorgeous banquet hall and flowers at The Great Hall" },
    { src: "/images/block-gallery-1/AlexanderSmith_M&T-412.jpg", alt: "The gorgeous banquet hall and flowers at The Great Hall" },
    { src: "/images/block-gallery-1/AlexanderSmith_M&T-736(1).jpg", alt: "The gorgeous banquet hall and flowers at The Great Hall" },
    { src: "/images/block-gallery-1/117A4066.jpg", alt: "The gorgeous banquet hall and flowers at The Great Hall" },
    { src: "/images/block-gallery-1/117A3773.jpg", alt: "The gorgeous banquet hall and flowers at The Great Hall" },
  ],
  [ENTRY_POINT.CORPORATE]: [
    { src: "/images/corporate/Corporate_11.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_12.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_13.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_16.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
  ],
  [ENTRY_POINT.BLACK_DIAMOND]: [
    { src: "/images/bdiamond/Bdmnd_31.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(95).jpg", alt: "The beautiul marble floors and chandeliers of The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(98).jpg", alt: "A floral arrangment inside The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(99).jpg", alt: "Minimalist decor and interors of The Great Hall" },
    { src: "/images/bdiamond/Bdmnd_40.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(133).jpg", alt: "A white floral center piece inside The Great Hall" },
    { src: "/images/block-gallery-1/AlexanderSmith_D_G-484.jpg", alt: "The gorgeous golden staircase at The Great Hall" },
    { src: "/images/block-gallery-1/AlexanderSmith_D_G-462.jpg", alt: "A white floral center piece inside The Great Hall" },
    { src: "/images/bdiamond/Bdmnd_9.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/AlexanderSmith_D&G-454(1).jpg", alt: "The gorgeous banquet hall and flowers at The Great Hall" },
    { src: "/images/block-gallery-1/AlexanderSmith_M&T-38(2).jpg", alt: "The gorgeous banquet hall and flowers at The Great Hall" },
    { src: "/images/block-gallery-1/AlexanderSmith_M&T-42(1).jpg", alt: "The gorgeous banquet hall and flowers at The Great Hall" },
    { src: "/images/bdiamond/Bdmnd_19.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/AlexanderSmith_M&T-412.jpg", alt: "The gorgeous banquet hall and flowers at The Great Hall" },
    { src: "/images/block-gallery-1/AlexanderSmith_M&T-736(1).jpg", alt: "The gorgeous banquet hall and flowers at The Great Hall" },
    { src: "/images/block-gallery-1/117A3773.jpg", alt: "The gorgeous banquet hall and flowers at The Great Hall" },
  ],
  [ENTRY_POINT.LOVE_IS_LOVE]: [
    { src: "/images/loveislove/LoveIsLove_14.jpg", alt: "A same-sex marriage couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(95).jpg", alt: "The beautiul marble floors and chandeliers of The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(98).jpg", alt: "A floral arrangment inside The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(99).jpg", alt: "Minimalist decor and interors of The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(133).jpg", alt: "A white floral center piece inside The Great Hall" },
    { src: "/images/block-gallery-1/AlexanderSmith_D_G-484.jpg", alt: "The gorgeous golden staircase at The Great Hall" },
    { src: "/images/block-gallery-1/AlexanderSmith_D_G-462.jpg", alt: "A white floral center piece inside The Great Hall" },
    { src: "/images/loveislove/LoveIsLove_7.jpg", alt: "A same-sex marriage couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/AlexanderSmith_D&G-454(1).jpg", alt: "The gorgeous banquet hall and flowers at The Great Hall" },
    { src: "/images/block-gallery-1/AlexanderSmith_M&T-38(2).jpg", alt: "The gorgeous banquet hall and flowers at The Great Hall" },
    { src: "/images/loveislove/LoveIsLove_8.jpg", alt: "A same-sex marriage couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/AlexanderSmith_M&T-42(1).jpg", alt: "The gorgeous banquet hall and flowers at The Great Hall" },
    { src: "/images/block-gallery-1/AlexanderSmith_M&T-412.jpg", alt: "The gorgeous banquet hall and flowers at The Great Hall" },
    { src: "/images/block-gallery-1/AlexanderSmith_M&T-736(1).jpg", alt: "The gorgeous banquet hall and flowers at The Great Hall" },
    { src: "/images/block-gallery-1/117A3773.jpg", alt: "The gorgeous banquet hall and flowers at The Great Hall" },
  ],
};

const GALLERY_IMAGES_4 = {
  [ENTRY_POINT.DEFAULT]: [
    { src: "/images/block-gallery-1/header-great-hall.jpg", alt: "A toast to the bride and groom inside The Great Hall" },
    { src: "/images/block-gallery-1/SG-Img-Party.jpg", alt: "A bartender pours drinks for the wedding party at The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(49).jpg", alt: "A cocktail bar at The Great Hall" },
    { src: "/images/block-gallery-1/AlexanderSmith_M&T-893.jpg", alt: "The festivities begin The Great Hall" },
    { src: "/images/block-gallery-1/117A4545.jpg", alt: "A bride and groom dancing at The Great Hall" },
    { src: "/images/block-gallery-1/117A4637.jpg", alt: "A bride and groom dancing at The Great Hall" },
    { src: "/images/block-gallery-1/117A4681.jpg", alt: "A bride and groom dancing at The Great Hall" },
    { src: "/images/block-gallery-1/117A4686.jpg", alt: "A bride and groom dancing at The Great Hall" },
  ],
  [ENTRY_POINT.CORPORATE]: [
    { src: "/images/corporate/Corporate_17.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_5.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_27.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_28.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
  ],
  [ENTRY_POINT.BLACK_DIAMOND]: [
    { src: "/images/bdiamond/Bdmnd_14.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/bdiamond/Bdmnd_39.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/bdiamond/Bdmnd_36.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/bdiamond/Bdmnd_27.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/bdiamond/Bdmnd_26.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
  ],
  [ENTRY_POINT.LOVE_IS_LOVE]: [
    { src: "/images/block-gallery-1/SG-img-(49).jpg", alt: "A cocktail bar at Shepstone Gardens" },
    { src: "/images/block-gallery-1/SG-img-(178).jpg", alt: "The bride and groom on the dancefloor at Shepstone Gardens" },
    { src: "/images/block-gallery-1/g204.jpg", alt: "A groom lifting up his bride on the dancefloor at Shepstone Gardens" },
    { src: "/images/block-gallery-1/AlexanderSmith-698.jpg", alt: "A crowd toasts together at Shepstone Gardens" },
  ],
};

const HEADING_1 = {
  [ENTRY_POINT.DEFAULT]: [
    "Before your I Do's",
  ],
  [ENTRY_POINT.CORPORATE]: [
    "Exquisite banqueting setups",
  ],
  [ENTRY_POINT.BLACK_DIAMOND]: [
    "Before your I Do's",
  ],
  [ENTRY_POINT.LOVE_IS_LOVE]: [
    "Before your I Do's",
  ],
};

const HEADING_2 = {
  [ENTRY_POINT.DEFAULT]: [
    "The Ceremony",
  ],
  [ENTRY_POINT.CORPORATE]: [
    "Classy conferencing",
  ],
  [ENTRY_POINT.BLACK_DIAMOND]: [
    "The Ceremony",
  ],
  [ENTRY_POINT.LOVE_IS_LOVE]: [
    "The Ceremony",
  ],
};

const HEADING_3 = {
  [ENTRY_POINT.DEFAULT]: [
    "The Reception",
  ],
  [ENTRY_POINT.CORPORATE]: [
    "Out of this world",
  ],
  [ENTRY_POINT.BLACK_DIAMOND]: [
    "The Reception",
  ],
  [ENTRY_POINT.LOVE_IS_LOVE]: [
    "The Reception",
  ],
};

const HEADING_4 = {
  [ENTRY_POINT.DEFAULT]: [
    "Dance the night away",
  ],
  [ENTRY_POINT.CORPORATE]: [
    "An event they'll never forget",
  ],
  [ENTRY_POINT.BLACK_DIAMOND]: [
    "Dance the night away",
  ],
  [ENTRY_POINT.LOVE_IS_LOVE]: [
    "Dance the night away",
  ],
};

const GreatHall = () => {
  return (
    <div>
      <Helmet>
        <title>The Great Hall | Weddings, Special Occasions & Corporate Functions</title>
        <meta
          name={"description"}
          content={"A magnificent venue where special occasions are nurtured into unforgettable moments to be cherished forever."}
        ></meta>
      </Helmet>
    
      <Header
        bgImg={"/images/header-great-hall.jpg"}
        headText={"THE GREAT HALL"}
      />

      {/* <LineSeparator noLine />
      <Specials specialsList={specialsData} /> */}

      <LineSeparator noLine />
      <LineSeparator noLine />

      <GreenBlockSection
        headText={"The Great Hall"}
        subHeadText={"Welcome to"}
      >
        <div className={"three-columns"}>
          <p className={"venue-intro-text"}>
            Elegance. Grace. Intimacy. Charm. The Great Hall exudes sophistication and modern design. Nestled away and hidden in a forest of trees, The Great Hall looks like a mythical fairytale castle, discovered by few. Striking and elegant are only a few of the words one could use to describe The Great Hall.
          </p>
          <p className={"venue-intro-text"}>
            The marbled floors, stone pillars and chandeliers do not only add a sense of opulence and timelessness to your wedding day, but also create the perfect canvas to let your imagination run wild. This elegant, modern and magical space is simply awe inspiring.
          </p>
          <p className={"venue-intro-text"}>
            The Great Hall Chapel with its intricate stone walls is absolutely breathtaking. With large glass windows surrounding the venue and magnificent glass roof elements, you can enjoy the surrounding greenery and rays of sunshine creeping into the venue, creating a truly romantic space to say “I Do”.
          </p>
        </div>
      </GreenBlockSection>

      <LineSeparator noLine />
      <LineSeparator noLine />
      <LineSeparator />
      <BlockHeading
        headText={HEADING_1[getEntryPoint()]}
        noBorder
      />

      <CoverFlowGallery
        images={GALLERY_IMAGES_1[getEntryPoint()]}
        noOvButton
      />

      <LineSeparator />
      <BlockHeading
        headText={HEADING_2[getEntryPoint()]}
        noBorder
      />

      <CoverFlowGallery
        images={GALLERY_IMAGES_2[getEntryPoint()]}
        noOvButton
      />

      <LineSeparator />
      <BlockHeading
        headText={HEADING_3[getEntryPoint()]}
        noBorder
      />

      <CoverFlowGallery
        images={GALLERY_IMAGES_3[getEntryPoint()]}
        noOvButton
      />

      <LineSeparator />
      <BlockHeading
        headText={HEADING_4[getEntryPoint()]}
        noBorder
      />

      <CoverFlowGallery
        images={GALLERY_IMAGES_4[getEntryPoint()]}
        noOvButton
      />

      <VideoSection
        videoUrl={"https://www.youtube.com/embed/n-4SHY8Z0VU"}
        headText={"Where dreams come true"}
        previewImg={"/images/TGH-Video-Tile.jpg"}
        noLines
      />

      <LineSeparator />
      <Contact />
    </div>
  );
};

export default GreatHall;