import React from "react";

const testimonials = [
  // {
  //   id: 1,
  //   snippet: "It has truly taken us an entire month to come down to earth after the most spectacular, romantic and unforgettable wedding at the Great Hall! Words simply cannot explain...",
  //   fullText: (
  //     <React.Fragment>
  //       <div className={"two-columns"}>
  //         <p>
  //           It has truly taken us an entire month to come down to earth after the most spectacular, romantic and unforgettable wedding at the Great Hall! Thank you to 
  //           the Shepstone Experience team for allowing us to be the first to marry there - it is obviously something that will stay with us always, and will connect 
  //           you all to us like family. You all shared in the vision of us getting married there, and we saw the capability of the Shepstone Experience team completing 
  //           a grandiose work of pure perfection that is the Great Hall! Ken, Karine, Tsuba and the rest of the team - your constant support, exceptional communication 
  //           and organisational skills, calm friendly demeanor and professionalism was over and above our expectations! We highly recommend The Great Hall, and every 
  //           single team member that make up the amazing Shepstone Experience!
  //         </p>
  //       </div>
  //       <p className={"test-full-name"}>Monica & Nelson</p>
  //     </React.Fragment>
  //   ),
  //   name: "Monica & Nelson",
  //   headImg: "/images/testimonials/Monica&Nelson.jpg",
  //   // videoUrl: "https://www.youtube.com/embed/5saaUXI7d-E",
  // },
  {
    id: 1,
    snippet: "We couldn't have asked for a more perfect wedding day! Thank you so so much for absolutely everything you and your team did to make our wedding so incredibly special.",
    fullText: (
      <React.Fragment>
        <div className={"two-columns"}>
          <p>
            Lee and I couldn't have asked for a more perfect wedding day! Thank you so so much for absolutely everything you and your team did to make our wedding so 
            incredibly special and even more than what we had envisioned. We truly had the most amazing day/night and we really felt like everything went so smoothly - 
            huge thanks to YOU! Wedding planning is a stressful and overwhelming task and you helped take away some of that stress in whatever way you could.
          </p>
          <p>
            For this we are extremely grateful. The fact that you had GOOD in-house suppliers such as decor and DJ made it so much easier for us. We really felt like we 
            could trust you guys and like we had made the best decision when choosing Shepstone Gardens. All of our guests had such a good time and were only full of 
            compliments for the day, and we know this wouldn't have been possible without the help of you and the SG team.
          </p>
        </div>
        <p className={"test-full-name"}>Mishka & Lee</p>
      </React.Fragment>
    ),
    name: "Mishka & Lee",
    headImg: "/images/testimonials/Mishka&Lee.jpg",
    // videoUrl: "https://www.youtube.com/embed/5saaUXI7d-E",
  },
  {
    id: 2,
    snippet: "Making a vision come to life as ethereal as ours, you have to have passion and love for what you do, and it is so easy to miss the finer details when overwhelmed by the beauty of Shepstone Gardens.",
    fullText: (
      <React.Fragment>
        <div className={"two-columns"}>
          <p>
            Making a vision come to life as ethereal as what we had, you could tell that there is passion and love for what you do, and it is so easy to miss the finer details 
            when overwhelmed by the beauty of Shepstone and emotions on the day. Jessica, our bubbly-as-ever coordinator with an upbeat spirit and carefree laugh kept me from 
            losing it a few times when chaos set in. Mira & her team sourced, and set up the aesthetic. I was in awe of the creations and what she did for us.
          </p>
          <p>
            Shepstone Gardens became more than a venue, it transformed into our Olympus, not only for how gorgeous it is, but because they were able to capture us with their 
            unbeatable team of wizards. Thank you for creating and being witness to our cheesy love story. Your staff are the friendliest and most professional people. We 
            honestly appreciate them. Thank you endlessly to everyone who was there from your side, who gave a piece of themselves to make our day that spectacular. We adore you.
          </p>
        </div>
        <p className={"test-full-name"}>Steph & Estaschia</p>
      </React.Fragment>
    ),
    name: "Steph & Estaschia",
    headImg: "/images/testimonials/Steph&Estaschia.jpg",
    // videoUrl: "https://www.youtube.com/embed/5saaUXI7d-E",
  },
  {
    id: 3,
    snippet: "We fell in love with Shepstone Gardens the day we saw it and booked it immediately for our wedding day all the way back in 2019!",
    fullText: (
      <React.Fragment>
        <div className={"two-columns"}>
          <p>
            Dean and I fell in love with Shepstone Gardens the day we saw it and booked it immediately for our wedding day all the way back in 2019! Always supportive, 
            understanding, accommodating, friendly, so patient and kind - the SG team made us feel like family. They made the planning process an easier one, the 
            postponement more bearable and our day nothing short of a perfect fairytale. Our guests & familywere amazed by the beauty of the venue and the 5-star service.
          </p>
          <p>
            Our wedding planner, Jess, went above and beyond for us! She is so much more than our planner, and more of our friend. Mira made our vision a reality and Ken 
            was always so fun, warm and helpful! Shepstone and the team will always have a very special place in our hearts and remain a huge part of our story!We are so 
            blessed and grateful to have had our fairytale come to life at the magical, transportive, most beautiful venue in JHB - Shepstone Gardens!
          </p>
        </div>
        <p className={"test-full-name"}>Dean & Andy</p>
      </React.Fragment>
    ),
    name: "Dean & Andy",
    headImg: "/images/testimonials/Dean&Andy.jpg",
    // videoUrl: "https://www.youtube.com/embed/5saaUXI7d-E",
  },
  {
    id: 4,
    snippet: "A huge thank you to the wonderful people at Shepstone Gardens: the high walled palace I cycled past hundreds of times which would become the place where our dreams became reality.",
    fullText: (
      <React.Fragment>
        <div className={"two-columns"}>
          <p>
            A huge thank you to the wonderful people at Shepstone Gardens: the high walled palace I cycled past hundreds of times which would become the place 
            where our dreams became reality. From the moment we arrived for a viewing on day one, to when we left after our wedding with a lifetime of memories 
            and tears in our eyes, everything was beyond perfect. What a day, what an event! Thank you all for your patience, encouragement and support through 
            COVID-19 as well.
          </p>
          <p>
            You took an inconsolable bride-to-be on 24 April 2020 and created a fairy tale wedding on 10 April 2021 – one year later. The biggest 
            thank you goes to Karine. She read us perfectly from day one and helped every second along the way. She gave us the chance to be part of it all: to breath, 
            to take it all in, to enjoy the day. She was present but never there. Her pink dress, sneakers and little bag on a bar stool just making it happen! She is 
            now family and so is Shepstone Gardens. You turned my fiancé’s hopes into the princess wedding she deserved.
          </p>
        </div>
        <p className={"test-full-name"}>Ferdie & Jeanette</p>
      </React.Fragment>
    ),
    name: "Ferdie & Jeanette",
    headImg: "/images/testimonials/Ferdie&Jeanette.jpg",
    // videoUrl: "https://www.youtube.com/embed/5saaUXI7d-E",
  },
  {
    id: 5,
    snippet: "I completely fell in love with Shepstone Gardens the moment I saw it on Pinterest; and I could not believe my eyes when I saw that this Italian-archictural jewel was so close to home!",
    fullText: (
      <React.Fragment>
        <div className={"two-columns"}>
          <p>
            I completely fell in love with Shepstone Gardens the moment I saw it on Pinterest; and I could not believe my eyes when I saw that this Italian-archictural 
            jewel was so close to home! Shepstone was the first and only venue my husband and I visited. We instantly knew that no other venue would be able to create 
            the same picture we had for confessing our love!
          </p>
          <p>
            From our first visit to the last minute of our wedding day, the Shepstone team was absolutely lovely! With 
            Jessica Gaisford as my wedding coordinator and Mira Cameron as my florist, we received endless support through the uncertain time of Covid-19. We got our 
            most perfect day! Their passion and dedication is inspiring and my flowers were out of this world!
          </p>
        </div>
        <p className={"test-full-name"}>Paula & Ruan</p>
      </React.Fragment>
    ),
    name: "Paula & Ruan",
    headImg: "/images/testimonials/Paula&Ruan.jpg",
    // videoUrl: "https://www.youtube.com/embed/5saaUXI7d-E",
  },
  {
    id: 6,
    snippet: "Sinead was literally the wedding planner I had always prayed for. One of the most common questions I got asked during the build-up to the wedding was “are you stressed?” and every time I was able to say “no”.",
    fullText: (
      <React.Fragment>
        <div className={"two-columns"}>
          <p>
            Sinead was literally the wedding planner I had prayed for from the minute we decided that we wanted a wedding planner. I say this confidently because one of 
            the most common questions I got asked during the build-up to the wedding was “are you stressed?” and every time I was able to say “no”. Sinead is not only 
            super organized, pro-active and a smart creative, but she is always humble, patient and kind. She came up with amazing ideas to bring our dream wedding to 
            life and always stayed on top of her tasks, constantly providing feedback to put us at ease.
          </p>
          <p>
            Needless to say, our wedding was talked about for quite some 
            time, we still get people in awe of how amazing it looked and what a great time everyone had. Sinead is a true gem as a wedding planner and if we could, 
            we would get married all over again just to work with her again. We are going to miss all the jokes, chuckles and memes we shared, and most importantly, 
            the words of encouragement. No amount of words can describe what an amazing wedding planner she is!
          </p>
        </div>
        <p className={"test-full-name"}>Tshidi & Mak</p>
      </React.Fragment>
    ),
    name: "Tshidi & Mak",
    headImg: "/images/testimonials/Tshidi&Mak.jpg",
    // videoUrl: "https://www.youtube.com/embed/5saaUXI7d-E",
  },
  {
    id: 7,
    snippet: "Thank you a million times over for the incredible job you all did, and the fact that you went over and above to make us feel special.",
    fullText: (
      <React.Fragment>
        <div className={"two-columns"}>
          <p>
            Thank you a million times over for the incredible job you all did, and the fact that you went over and above to make us feel special. 
            I have never met people with bigger hearts than all of you, 
          </p>
          <p>
            and we will forever be so grateful for how blessed we were to have you play such a huge role in making our day so filled with blessings.
          </p>
        </div>
        <p className={"test-full-name"}>Jenna & Luca</p>
      </React.Fragment>
    ),
    name: "Jenna & Luca",
    headImg: "/images/testimonials/Jenna&Luca.jpg",
    // videoUrl: "https://www.youtube.com/embed/5saaUXI7d-E",
  },
];

export default testimonials;