import "./contact.css";

import React from "react";
import { Button } from "@material-ui/core";
import { WhatsApp } from "@material-ui/icons";
import { Link } from "react-router-dom";

import BlockHeading from "./BlockHeading";

const styles = {
  contactButton: {
    margin: "0 10px 20px 10px",
    width: "calc(50% - 20px)",
    height: 60,
    textTransform: "none",
  },
  contactButtonInner: {
    width: "100%",
    height: "100%",
    textTransform: "none",
  },
};


const Contact = () => {
  return (
    <section className={"contact-section"}>
      <BlockHeading
        headText={"Schedule a viewing with us"}
        noBorder
      />
      <div className={"contact-cont"}>
        <Button
          href={"https://wa.me/27678170890"}
          target={"_blank"}
          startIcon={<WhatsApp />}
          variant={"contained"}
          color={"primary"}
          style={styles.contactButton}
        >
          Start chat
        </Button>
        <Link
          to={"/Contact"} 
          style={styles.contactButton}
        >
          <Button
            variant={"contained"}
            color={"primary"}
            style={styles.contactButtonInner}
          >
            Contact us
          </Button>
        </Link>
       
      </div>
    </section>
  );
};

export default Contact;