import "./iconsGroup.css";

import React from "react";


const BlockHeading = ({ icons }) => {
  return (
    <section className={"icon-group-section"}>
      <div className={"icon-group-cont standard-width"}>
        {
          icons.map((icon, i) => (
            <div
              className={"icon-item-cont"}
              key={i}
            >
              <img
                className={"icon-item-img"}
                src={icon.img}
                alt={icon.label}
              />
              <p className={"icon-item-label"}>{icon.label}</p>
            </div>
          ))
        }
      </div>
    </section>
  );
};

export default BlockHeading;