import React from "react";

import GalleryBlock from "./GalleryBlock";


const blogPosts = [
  {
    id: 1,
    previewImage: "/images/blog/post1_thumb.jpg",
    previewText: "These 12 essential tips & tricks will make such a difference you won't believe you hadn't thought of them yourself.",
    head: "12 Wedding Planning Tips & Tricks",
    date: "17 September 2016",
    content: <div>
      <p>
        When planning your wedding, there are things that are nice to know, and then there are things
        that you need to know – advice thats is so essential that any bride who’s lucky enough to hear
        it will truly appreciate it before and after the very special day. Check out our indispensable
        planning secrets below.
      </p>

      <GalleryBlock
        image={"blog/post1_1.jpg"}
        classOverride={"gallery-block-full"}
      />

      <h3 className={"subhead"}>1. Guests Do Come First</h3>
      <p>
        Having a rough idea on how many guests to expect is the number one thing to consider, even
        before searching for a venue. Once you have a number then the first thing to do when contacting
        a venue is to ask their maximum AND the minimum. It is always best to keep the details in
        mind when viewing venues such as space for tables, bustling waiters, dance floor and any
        other weird and wonderful ideas that you may have.
      </p>

      <h3 className={"subhead"}>2. Investigate The Calendar</h3>
      <p>
        Although having the perfect date that just happens to roll off the tongue or a date that
        symbolises something special to the both of you it is so so so worth having an open mind of
        what dates are actually available. There is really no point in calling a venue and stating
        that, and only that ‘your wedding day IS September 24th’. You never know what may be available
        and if your dream venue only has a few dates left it is so worth exploring these (especially
        for the budget conscious).
      </p>

      <h3 className={"subhead"}>3. Listen To Mother Nature</h3>
      <p>
        Luckily here in South Africa we are absolutely spoilt for choice when it comes to choosing a
        season to get married in. We have the delight of never having to worry about the snow factor
        and even in the most rainy of Decembers, we simply choose to walk down the aisle either
        before or after the damned 4pm showers. And if you want a sunset ceremony, make sure you know
        when to say your vows by checking www.timeanddate.com
      </p>
      
      <h3 className={"subhead"}>4. Take Advantage Of That Credit Card</h3>
      <p>
        If you are going to use a credit card to pay towards the high cost of your wedding then sign up
        for one with a rewards program. Whether it gives you airline miles to spend on your honeymoon
        or great shopping deals, consolidating all wedding-related purchases will help accumulate
        thousands of reward points to put back into making those incredible memories.
      </p>

      <h3 className={"subhead"}>5. Word Of Mouth</h3>
      <p>
        Let one supplier lead you to another. Your wedding photographer can tell you which florist’s
        blooms have that absolute stunning factor, and your coordinator will know which musician will
        set the mood for cocktail hour.
      </p>

      <h3 className={"subhead"}>6. Lighten The Load</h3>
      <p>
        The easiest and most effective way to trim your wedding budget is unfortunately to reduce the
        guest list. Remember, half of your wedding expenses go towards wining and dining your guests.
        If its costing you R1,500 per person, eliminating one table of 10 can save you R15,000.
      </p>

      <GalleryBlock
        image={"blog/post1_2.jpg"}
        classOverride={"gallery-block-full"}
      />

      <h3 className={"subhead"}>7. Get Organised</h3>
      <p>
        Compile all your correspondence with suppliers, notes from meetings, and photos or magazine
        clippings of inspiration. Set up a special email address dedicated to your wedding, and save
        all your suppliers contact details into your phone.
      </p>

      <h3 className={"subhead"}>8. The More Waiters, The Better</h3>
      <p>
        Typically, you need one waiter per 10 guests for a plated meal and about half of that for a
        buffet. Having enough staff is crucial when it comes to pre-drinks and more importantly
        throughout the reception. Dirty glasses and plates are not what you want or need in the
        background of your wedding photos.
      </p>

      <h3 className={"subhead"}>9. Make A Kids Policy</h3>
      <p>
        You have four choices: You can welcome all children with open arms; you can decide on
        ‘adults only’; immediate family children only; or, provide nannies and childcare. Most
        venues will help supply this and will also happily provide a children’s room.
      </p>

      <h3 className={"subhead"}>10. One Step At A Time</h3>
      <p>
        Put together a logical wedding planning schedule and tick things off as you get them completed.
        Don’t hire any suppliers until you have secured the venue and date; don’t design your cake
        before you have envisioned your flowers; and don’t book any entertainment until you have
        settled on space.
      </p>

      <h3 className={"subhead"}>11. Provide Accurate Directions</h3>
      <p>
        Make sure that your guests know where they are going. We may take for granted that not everyone
        has the latest google maps app on their phone so it is always a nice idea to creatively display
        a small map in the invitation. You can also add any little details about parking, etc here.
      </p>

      <h3 className={"subhead"}>12. Trust Your Marriage Officer</h3>
      <p>
        Before the day takes place 100% confirm that your marriage officer has absolutely everything you
        need and everything prepared correctly. This includes passport photos and witnesses names,
        as well as I.D numbers. If one of you is not South African then pay even more attention
        because there may be some extra little jobs to take care of, so be sure to leave plenty of time.
      </p>
    </div>,
  },
  {
    id: 6,
    previewImage: "/images/blog/post6_thumb.jpg",
    previewText: "Here are the 6 things that you should definitely not skimp on according to our coordinators.",
    head: "The Wrong Things to Skimp On",
    date: "27 August 2018",
    content: <div>
      <h3 className={"subhead"}>Photographer</h3>
      <p>
        It is very important to make sure you choose a Photographer whose images really speak to you
        and who you have a good rapport with. These images will be with you for the rest of your life
        and you want to make sure they have captured your personalities and special moments throughout
        your day!
      </p>
      <GalleryBlock
        image={"blog/post6_1.jpg"}
        classOverride={"gallery-block-full"}
      />

      <h3 className={"subhead extra-top-margin"}>Makeup</h3>
      <p>
        A good makeup artist is vital on your wedding day, even if you have a fantastic photographer
        without a proper makeup artist and products your makeup could look dull in photos. A good
        makeup artist will also ensure your makeup stays on all day. A proper makeup artist also
        makes sure you have the perfect look for your face shape and colouring.
      </p>
      <GalleryBlock
        image={"blog/post6_2.jpg"}
        classOverride={"gallery-block-full"}
      />

      <h3 className={"subhead extra-top-margin"}>DJ</h3>
      <p>
        Music and sound are super important and can make or break your function. Music is also very
        personal and needs to represent the couple and their personalities. There are important
        moments during your day and you need the correct music to play at the right time. The DJ also
        needs to be able to read the crowd and be able to adjust the sound to match the vibe of the
        evening.
      </p>
      <GalleryBlock
        image={"blog/post6_3.jpg"}
        classOverride={"gallery-block-full"}
      />

      <h3 className={"subhead extra-top-margin"}>Food</h3>
      <p>
      Food is something your guests will remember long after your wedding. It does not matter if it’s
      plated or buffet... Just make sure its delicious and filling!
      </p>
      <GalleryBlock
        image={"blog/post6_4.jpg"}
        classOverride={"gallery-block-full"}
      />

      <h3 className={"subhead extra-top-margin"}>Bar</h3>
      <p>
        A bar can add a super fun aspect to your wedding day and you can have outdoor bars, gin bars,
        make your own cocktail bar or a craft beer station!
      </p>
      <GalleryBlock
        image={"blog/post6_5.jpg"}
        classOverride={"gallery-block-full"}
      />

      <h3 className={"subhead extra-top-margin"}>Décor and Flowers</h3>
      <p>
        This is really where your love and personality comes alive, flowers are everywhere on your
        wedding day. Flowers are important in creating the mood and feeling of your wedding day, are
        a form of expression and add a sense of elegance and timelessness to your special day!
      </p>
      <GalleryBlock
        image={"blog/post6_6.jpg"}
        classOverride={"gallery-block-full"}
      />
    </div>,
  },
  {
    id: 7,
    previewImage: "/images/blog/post7_thumb.jpg",
    previewText: "These are our picks for the 12 must-have shots to be captured on your wedding day.",
    head: "12 Essential Wedding Day Shots",
    date: "8 October 2018",
    content: <div>
      <h3 className={"subhead"}>1. The bride's gown and accessories</h3>
      <p>
        Before you slip into your dress and accessories take a picture to capture every gorgeous detail.
      </p>
      <GalleryBlock
        image={"blog/post7_1.jpg"}
        classOverride={"gallery-block-full"}
      />

      <h3 className={"subhead extra-top-margin"}>2. The bouquets</h3>
      <p>
        Capturing the flowers on the day means you will have memories of them forever.
      </p>
      <GalleryBlock
        image={"blog/post7_2.jpg"}
        classOverride={"gallery-block-long"}
      />

      <h3 className={"subhead extra-top-margin"}>3. Father and mother of the bride seeing the bride for the first time</h3>
      <p>
        This will be one of the most nostalgic photos of the day and will take the parents back to when
        their daughter was just a little girl.
      </p>
      <GalleryBlock
        image={"blog/post7_3.jpg"}
        classOverride={"gallery-block-full"}
      />

      <h3 className={"subhead extra-top-margin"}>4. Bride with her bridesmaids</h3>
      <p>
        Before the ceremony and all the festivities begin gather the bridesmaids and get a few group
        shots of all the girls together.
      </p>
      <GalleryBlock
        image={"blog/post7_4.jpg"}
        classOverride={"gallery-block-long"}
      />

      <h3 className={"subhead extra-top-margin"}>5. Close up shot of the rings</h3>
      <p>
        Getting a good shot of both your rings is a great keepsake.
      </p>
      <GalleryBlock
        image={"blog/post7_5.jpg"}
        classOverride={"gallery-block-long"}
      />

      <h3 className={"subhead extra-top-margin"}>6. Groom with groomsmen</h3>
      <p>
        A life long brotherhood which needs to be captured.
      </p>
      <GalleryBlock
        image={"blog/post7_6.jpg"}
        classOverride={"gallery-block-full"}
      />

      <h3 className={"subhead extra-top-margin"}>7. Grooms expression while waiting for his bride</h3>
      <p>
        It's an unforgettable moment between the couple.
      </p>
      <GalleryBlock
        image={"blog/post7_7.jpg"}
        classOverride={"gallery-block-full"}
      />

      <h3 className={"subhead extra-top-margin"}>8. The kiss</h3>
      <p>
        You can have some fun with this shot and again such a sentimental and lovely
        moment to treasure.
      </p>
      <GalleryBlock
        image={"blog/post7_8.jpg"}
        classOverride={"gallery-block-full"}
      />

      <h3 className={"subhead extra-top-margin"}>9. Bride and groom with entire wedding party</h3>
      <p>
        Don’t forget to take a group shot of all your best friends by your side on your special day.
      </p>
      <GalleryBlock
        image={"blog/post7_9.jpg"}
        classOverride={"gallery-block-full"}
      />

      <h3 className={"subhead extra-top-margin"}>10. The cake</h3>
      <p>
        Before you cut the cake take a shot as you will want a memory of your decadent dessert.
      </p>
      <GalleryBlock
        image={"blog/post7_10.jpg"}
        classOverride={"gallery-block"}
      />

      <h3 className={"subhead extra-top-margin"}>11. Bride and groom's first dance with their parents</h3>
      <p>
        These can be some of the most tender shots and will last a thousand years of the special
        bond between parent and child.
      </p>
      <img
        src={"/images/blog/post7_11.jpg"}
        className={"full-width"}
        style={{ margin: "0 0.5%" }}
      />
      <GalleryBlock
        image={"blog/post7_12.jpg"}
        classOverride={"gallery-block-full"}
      />

      <h3 className={"subhead extra-top-margin"}>12. Guests dancing</h3>
      <p>
        This shows the fun side of the wedding and all your friends letting loose and celebrating
        your special day.
      </p>
      <GalleryBlock
        image={"blog/post7_13.jpg"}
        classOverride={"gallery-block-full"}
      />
    </div>,
  },
  {
    id: 8,
    previewImage: "/images/blog/post8_thumb.jpg",
    previewText: "Did you know Shepstone Gardens is featured in What's on in Joburg? Have a look here!",
    head: "What's on in Joburg Feature",
    date: "3 March 2019",
    content: <div>
      <h3 className={"subhead"}>See the Shepstone Gardens Feature</h3>
      <p>
        Shepstone Gardens is now proudly featured in "What's on in Joburg". Click the link below to view our listing
        on their site.
      </p>
      <p>
        <a
          target={"_blank"}
          href={"https://whatsoninjoburg.com/post/garden-and-outdoor-wedding-venues-near-gauteng/?fbclid=IwAR1sL2GRrRGjHWBZ9XuVQa0eejDQisyINZCJQbcvLYDszf2vXK3MH0llHzY"}
          rel={"noreferrer"}
        >https://whatsoninjoburg.com/post/garden-and-outdoor-wedding-venues-near-gauteng/</a>
      </p>
      <GalleryBlock
        image={"blog/post8_1.jpg"}
        classOverride={"gallery-block-full"}
      />
    </div>,
  },
  {
    id: 9,
    previewImage: "/images/blog/post9_thumb.jpg",
    previewText: "Check out the amazing article about Shepstone Gardens recently featured by Home and Leisure.",
    head: "Home and Leisure Feature",
    date: "4 March 2019",
    content: <div>
      <h3 className={"subhead"}>Hidden Treasure</h3>
      <p>
        "Joburg’s Shepstone Gardens is a mix of verdant glamour and intriguing concealed spaces" writes Tess Paterson from
        Home and Leisure. Have a look at the full article using the link below.
      </p>
      <p>
        <a
          target={"_blank"}
          href={"https://www.houseandleisure.co.za/content/hidden-treasure"}
          rel={"noreferrer"}
        >https://www.houseandleisure.co.za/content/hidden-treasure</a>
      </p>
      <GalleryBlock
        image={"blog/post9_1.jpg"}
        classOverride={"gallery-block-long"}
      />
      <GalleryBlock
        image={"blog/post9_2.jpg"}
        classOverride={"gallery-block-long"}
      />
      <p>
        Photos by Christoph Hoffmann
      </p>
    </div>,
  },
  {
    id: 14,
    previewImage: "/images/blog/post14_thumb.jpg",
    previewText: "We've found some cool and easy ways to keep kids entertained during your wedding!",
    head: "Having kids at your wedding?",
    date: "8 October 2019",
    content: <div>
      <p>Cool ways to keep the kids entertained!</p>
      <div className={"c_blog-half-page"}>
        <h3 className={"subhead"}>Children’s Teepee Tent</h3>
        <p>
          Filled with colouring in books,
          crayons, pillows and blankets. The kids
          will love having a tent to hang out in. 
        </p>
        <img
          src={"/images/blog/post14_1.jpg"}
          className={"full-width standard-box"}
          style={{ margin: "0 0.5%" }}
        />
      </div>

      <div className={"c_blog-half-page"}>
        <h3 className={"subhead"}>Party Packs</h3>
        <p>
          Activity bags for the kids filled with
          crosswords, snacks, slime, crayons.
          Can be used as kids take home gifts.
        </p>
        <img
          src={"/images/blog/post14_2.jpg"}
          className={"full-width standard-box"}
          style={{ margin: "0 0.5%" }}
        />
      </div>

      <div className={"c_blog-half-page"}>
        <h3 className={"subhead"}>Lawn Games</h3>
        <p>
          Big sized Jenga, OX’s, croquet,
          pick up sticks & chess will keep the
          kids (adults) entertained the whole day.
        </p>
        <GalleryBlock
          image={"blog/post14_3.jpg"}
          classOverride={"gallery-block standard-box"}
        />
      </div>

      <div className={"c_blog-half-page"}>
        <h3 className={"subhead"}>Kids Menu</h3>
        <p>
          Get your caterer to do a kids menu
          fill tummies equals happy kids. Hamburgers,
          pizza & pasta are all perfect.
        </p>
        <GalleryBlock
          image={"blog/post14_4.jpg"}
          classOverride={"gallery-block standard-box"}
        />
      </div>

      <div className={"c_blog-half-page"}>
        <h3 className={"subhead"}>I Spy</h3>
        <p>
          Print a page with a bunch of
          things for the kids to look out for.
          Let them play ‘I spy’ throughout the
          day and offer a prize to the winner
        </p>
        <img
          src={"/images/blog/post14_5.jpg"}
          className={"full-width standard-box"}
          style={{ margin: "0 0.5%" }}
        />
      </div>

      <div className={"c_blog-half-page"}>
        <h3 className={"subhead"}>Kids Juice station</h3>
        <p>
          A mocktail & juice station will make the
          kids feel super special. Mason jars, edible
          flowers & eco friendly fancy straws will
          delight the little ones. 
        </p>
        <img
          src={"/images/blog/post14_6.jpg"}
          className={"full-width standard-box"}
          style={{ margin: "0 0.5%" }}
        />
      </div>

      <div className={"c_blog-half-page"}>
        <h3 className={"subhead"}>Colouring in books</h3>
        <p>
          You can have colouring in
          books that follow the theme
          of your wedding, keeping the
          kids entertained for hours.
        </p>
        <img
          src={"/images/blog/post14_7.jpg"}
          className={"full-width standard-box"}
          style={{ margin: "0 0.5%" }}
        />
      </div>

      <div className={"c_blog-half-page"}>
        <h3 className={"subhead"}>S’mores bar</h3>
        <p>
          Having a winter wedding? A S’more’s
          bar will be a hit with kids and adults alike!
          You can even have a hot chocolate
          & cappuccino station.
        </p>
        <img
          src={"/images/blog/post14_8.jpg"}
          className={"full-width standard-box"}
          style={{ margin: "0 0.5%" }}
        />
      </div>

      <h3 className={"subhead"}>Get creative & come up with ideas that relate to you as a couple!</h3>
    </div>,
  },
  {
    id: 31,
    previewImage: "/images/blog/post31_thumb.jpg",
    previewText: "South Africa is fast becoming a favourite among international couples as the perfect country to host a destination wedding.",
    head: "The Perfect Destination Wedding Venue",
    date: "10 December 2021",
    content: <div>
      <p>
        South Africa is fast becoming a favourite among international couples as the perfect country to host a destination wedding, 
        and at Shepstone Gardens we have had the priviledge of hosting many international couples and their family and friends for 
        some of the most beautiful ceremonies and receptions.
      </p>

      <GalleryBlock
        image={"blog/Destination-Blog-1.jpg"}
        classOverride={"gallery-block-long"}
      />
      <GalleryBlock
        image={"blog/Destination-Blog-11.jpg"}
        classOverride={"gallery-block-long"}
      />

      <br/><br/>
      <h2 className={"subhead"}>Planning</h2>
      <h4>Communication is Key</h4>
      <p>
        Planning a destination wedding from afar can be a stressful affair but we try to make it as easy as possible for our brides. 
        Shepstone Gardens aims to ensure that we have open lines of communication with our brides at all times. For our international 
        brides we have our whatsapp line and email as well as zoom for a more personalised face-to-face chat experience.
      </p>

      <GalleryBlock
        image={"blog/Destination-Blog-2.jpg"}
        classOverride={"gallery-block"}
      />
      <GalleryBlock
        image={"blog/Destination-Blog-7.jpg"}
        classOverride={"gallery-block"}
      />

      <h4>Personalized Service</h4>
      <p>
        At Shepstone Gardens, every bride is assigned a personal co-ordinator for her special day. We also have the option of full wedding 
        planner for our brides who can’t be as hands on or who simply require a little extra assistance. We find that the full wedding 
        planning package works very well for our overseas brides who might not be able to be here in person to organise food, cake, hair 
        and makeup or photographer. Your full wedding planner can assist with all these things as well as advise on the best choices for 
        your flowers and décor. She can also schedule all your appointments consecutively so that so can fly in and get your wedding planning 
        done and dusted in one week! If only everything in life was so easy!
      </p>

      <GalleryBlock
        image={"blog/Destination-Blog-6.jpg"}
        classOverride={"gallery-block-full"}
      />
      
      <br/><br/>
      <h2 className={"subhead"}>The Finer Details</h2>
      <h4>Flowers & Decor</h4>
      <p>
        An added bonus for both local and international brides is that we have a world-class flower and décor team who manage to blow us away 
        with every wedding that they do! Mira and her team deliver floral creations tailored to each bride and her budget – meaning that whether 
        you are going for a subtle look or a flower and décor extravaganza – you are in the best possible hands. Mira is available over email or 
        whatsapp and, once you book your date, will chat to you about quotes and doing a mock-up to sign off on your final floral look!
      </p>

      <GalleryBlock
        image={"blog/Destination-Blog-10.jpg"}
        classOverride={"gallery-block-long"}
      />
      <GalleryBlock
        image={"blog/Destination-Blog-9.jpg"}
        classOverride={"gallery-block-long"}
      />

      <h4>Music</h4>
      <p>
        Our in-house DJ, Henk Kotze, has done hundreds of weddings at Shepstone Gardens and comes with all his own equipment. We encourage our 
        brides to chat to Henk about their music tastes and sound requirements. Were you imagining a violinist or singer for your welcome space? 
        Uplighters for your dance floor? A stage for your main table? Henk is your man! He can help you organise the above and help you with a 
        list of our trusted suppliers to create that magical ambiance on your big day!
      </p>

      <GalleryBlock
        image={"blog/Destination-Blog-3.jpg"}
        classOverride={"gallery-block"}
      />
      <GalleryBlock
        image={"blog/Destination-Blog-4.jpg"}
        classOverride={"gallery-block"}
      />

      <h4>Accommodation</h4>
      <p>
        We know that for many of our international brides, accommodation is a big concern. With family and friends flying in from all over the world, 
        you want them to be able to stay close and feel part of the action. We have on site accommodation for our couples on the night of the wedding 
        and have close working relationships with many hotels and guesthouses in the area. This means your guests can choose what works best for them, 
        or they can book rooms to in a hotel or guesthouse chosen by you, thus keeping your bridal party together!
      </p>

      <GalleryBlock
        image={"blog/Destination-Blog-8.jpg"}
        classOverride={"gallery-block-full"}
      />

      <br/><br/>
      <h2 className={"subhead"}>Peace Of Mind</h2>
      
      <p>
        At Shepstone Gardens we do our very best to ensure that all our international brides feel part of our family even though they are planning their 
        wedding from far away. All our in-house services are aimed at making it easier for our brides to have fun during the planning process and be able 
        to relax on their big day, knowing that they are in good hands. We understand that planning a wedding can be daunting, and we hope that our 
        all-in-one experience means that our brides enjoy each step of the way to saying ‘I do’.
      </p>

    </div>,
  },
  {
    id: 32,
    previewImage: "/images/blog/post32_1.jpg",
    previewText: "7 big and bold trends that you can expect to see in 2024 weddings. If you're planning your wedding, you won't want to miss this!",
    head: "Wedding Trends for 2024 that we cannot wait to see!",
    date: "4 March 2024",
    content: <div>
      <p>
        As we move forward into the new year, we take a look at up-and-coming trends that are happening in weddings around the world. From timeless
        classics to more untraditional and new ideas, 2024 looks like it will be the hottest bridal year yet! 
      </p>

      <p>
        The Shepstone Experience team is looking forward to another year of new trends, with the promise of 2024 being bigger, bolder and
        with more beautiful ways to celebrate love. Let’s take a look at what we expect to see more of:
      </p>

      <h3 className={"subhead extra-top-margin"}>1. Wow Moments</h3>
      <p>
        Couples are focusing on giving their guests a moment that they will be talking about for weeks after, or a statement moment for
        photos. From a unique food display that makes a statement, a surprise choreographed dance by the bridal party to a grand floral
        statement piece, couples have a variety of options to choose from. One of our favourites is the classic champagne tower that
        provides a ‘wow’ photo moment, entertainment and drinks during canapés while still providing an element of retro design. 
      </p>
      <GalleryBlock
        image={"blog/post32_1.jpg"}
        classOverride={"gallery-block-long"}
      />

      <h3 className={"subhead extra-top-margin"}>2. Small or No Wedding Party</h3>
      <p>
        Lately, we see that couples would like the ceremony to be more focused on them and their union, rather than having large wedding
        parties that may distract from that. We are expecting smaller wedding parties, with possibly just a maid of honour and a best
        man, and if they do have a wedding party, they are more often than not seated during the ceremony. This doesn’t mean you can’t get
        ready with your closest friends though. We still see selected guests coming to get ready with the couple in our beautiful bridal
        suite and man cave, and then simply joining the rest of the guests before the ceremony.
      </p>
      <GalleryBlock
        image={"blog/post32_2.jpg"}
        classOverride={"gallery-block-long"}
      />

      <h3 className={"subhead extra-top-margin"}>3. Mini cakes & desserts and snack displays</h3>
      <p>
        Contributing to the ‘wow moments’ mentioned in trend #1, couples are opting for a variety of sweet treats - not just a
        wedding cake. This isn’t a new trend, but it is becoming more popular and is the best treat for you and your guests, especially
        towards the end of the night! We’ve seen exquisite sweet stations, popcorn bars, mini dessert displays and we can’t wait to
        see what our 2024 brides come up with! 
      </p>
      <GalleryBlock
        image={"blog/post32_3.jpg"}
        classOverride={"gallery-block-long"}
      />

      <h3 className={"subhead extra-top-margin"}>4. Themed dress code</h3>
      <p>
        Couples are opting to guide their guests a little more when it comes to what to wear, by adding a theme or colour palette to the
        dress code. This gives a little more direction than the usual ‘formal’ or ‘smart-casual’ guidelines. We also love to see how
        everyone's attire adds to the aesthetic and really comes together in photos!
      </p>
      <GalleryBlock
        image={"blog/post32_4.jpg"}
        classOverride={"gallery-block"}
      />

      <h3 className={"subhead extra-top-margin"}>5. Unique guest books</h3>
      <p>
        Couples are opting for something a little more eccentric for their guests, rather than the usual written guest books. Some of our
        favourites are the audio guestbooks, with the retro phones, polaroid guest books and guest book jenga!
      </p>
      <GalleryBlock
        image={"blog/post32_5.jpg"}
        classOverride={"gallery-block-long"}
      />

      <h3 className={"subhead extra-top-margin"}>6. Statement-making seating plans</h3>
      <p>
        Seating plans that are interactive and make statements are starting to pop up and we love them. From champagne and shooters to
        fortune cookies, we love to see guests’ excitement from something usually so formal.
      </p>
      <GalleryBlock
        image={"blog/post32_6.jpg"}
        classOverride={"gallery-block"}
      />

      <h3 className={"subhead extra-top-margin"}>7. Patterned bridesmaid dresses</h3>
      <p>
        Breaking away from the traditional, uniformed bridesmaid dresses, brides are allowing their retinue to show a little more
        individuality. They are opting for ‘mixed-matched’ patterned dresses that bring a playful, bohemian vibe. Brides are choosing
        either a colour palette or a specific flower theme, so there is still a common element in each dress. The result brings a
        whimsical touch to the overall wedding aesthetic and can also bring a modern touch making the day more vibrant and memorable.
      </p>
      <GalleryBlock
        image={"blog/post32_7.jpg"}
        classOverride={"gallery-block-long"}
      />

      <p>
        We know that 2024 is going to be a spectacular year for all of our couples and we cannot wait to see the ideas that they bring
        to the table! Maybe you, as our next bride can introduce a new wedding trend. 
      </p>
    </div>,
  },
].reverse();

export default blogPosts;