import React from "react";
import { Button } from "@material-ui/core";

const styles = {
  finalCTAButton: {
    textTransform: "none",
    backgroundColor: "#fff",
  },
};

const special = [
  // {
  //   id: 1,
  //   snippet: "Get married before the 31st of August 2023 to obtain 100% off your venue hire fee at Shepstone Gardens!",
  //   heading: "♥ 100 DAYS OF LOVE SPECIAL ♥",
  //   fullText: (
  //     <React.Fragment>
  //       <div className={"two-columns"}>
  //         <p>
  //           We are celebrating 100 days of love!<br/><br/>
  //           Have your next wedding, function or conference at Shepstone Gardens before 31 August 2023 to qualify for 100% discount on venue-hire!
  //         </p>
  //         <p>
  //           You heard right - 100% off your next event's venue-hire at South Africa's most exclusive luxury venue.<br/><br/>
  //           Chat with us on WhatsApp to find out more!
  //         </p>
  //         <br/>
  //       </div>
  //     </React.Fragment>
  //   ),
  //   ctaButton: (
  //     <React.Fragment>
  //       <Button
  //         variant={"outlined"}
  //         href={"https://wa.me/27678170890"}
  //         target={"_blank"}
  //         color={"primary"}
  //         style={styles.finalCTAButton}
  //       >
  //         Chat to us to find out more!
  //       </Button>
  //     </React.Fragment>
  //   ),
  //   name: "100 Days of Love Special",
  //   headImg: "/images/special-100-days.jpg",
  // },
  {
    id: 1,
    snippet: "Don't miss the perfect opportunity to experience The Great Hall & Shepstone Gardens at night!",
    heading: "♥ EXCLUSIVE DATE NIGHT AT SHEPSTONE GARDENS ♥",
    fullText: (
      <React.Fragment>
        <div className={"two-columns"}>
          <p>
            Don't miss the perfect opportunity to experience The Great Hall & Shepstone Gardens at night!
            <br/><br/>
            Join us under the stars as we wine and dine together at Johannesburg's most exclusive venue.
            <br/><br/>
            Spots are extrememly limited - so make sure you book online well in advance. See you there!
          </p>
          <p>
            Each ticket includes:<br/><br/>
            - A delicious harvest plate to share<br/>
            - A glass of bubbles arrival<br/>
            - Live music<br/>
            - Cash bar available
            <br/><br/>
            - Price: R650 per couple<br/>
            - Date: Saturday, 30 September 2023
          </p>
        </div>
        <br/>
      </React.Fragment>
    ),
    ctaButton: (
      <React.Fragment>
        <Button
          variant={"outlined"}
          href={"/shop"}
          color={"primary"}
          style={styles.finalCTAButton}
        >
          More Details
        </Button>
      </React.Fragment>
    ),
    name: "Shepstone Experience Date Night",
    headImg: "/images/SG-Night-Open-Day-2.jpg",
  },
];

export default special;