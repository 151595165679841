import "./coverFlowGallery.css";

import React from "react";
import { Button } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";

const styles = {
  pagingButton: {
    minWidth: 50,
    padding: 0,
    position: "absolute",
    height: "100%",
    top: 0,
    zIndex: 3,
    textTransform: "none",
  },
  pagingButtonLeft: {
    left: 0,
    textTransform: "none",
  },
  pagingButtonRight: {
    right: 0,
    textTransform: "none",
  },
  pagingIcon: {
    width: 45,
    height: 45,
    color: "#6F766B",
    textTransform: "none",
  },
  ovButton: {
    width: "65%",
    maxWidth: 250,
    minHeight: 50,
    textTransform: "none",
  },
};


const CoverFlowGallery = ({ images, noOvButton=false }) => {
  const [page, setPage] = React.useState(0);
  const [lightboxIndex, setLightboxIndex] = React.useState(-1);

  const imagesLen = images.length;
  const renderedImages = [
    images[((page-2) % imagesLen + imagesLen) % imagesLen], //This is to circularly access the array from the active page
    images[((page-1) % imagesLen + imagesLen) % imagesLen],
    images[((page) % imagesLen + imagesLen) % imagesLen],
    images[((page+1) % imagesLen + imagesLen) % imagesLen],
    images[((page+2) % imagesLen + imagesLen) % imagesLen],
  ];

  return (
    <section className={"cf-gallery-section"}>
      <div className={"cf-gallery-cont standard-width"}>
        <Button
          style={{
            ...styles.pagingButton,
            ...styles.pagingButtonLeft,
          }}
          onClick={() => setPage(((page-1) % imagesLen + imagesLen))}
        >
          <ChevronLeft style={styles.pagingIcon} />
        </Button>
        {
          renderedImages.map((img, index) => (
            <div
              key={img.src}
              className={[
                "cf-image-cont",
                `pos-${index}`,
              ].join(" ")}
              onClick={() => {
                if (index === 2) {
                  setLightboxIndex(images.indexOf(img));
                }
                else {
                  setPage(((page + index - 2) % imagesLen + imagesLen));
                }
              }}
            >
              <img
                className={"cf-image"}
                src={img.src}
                alt={img.alt}
              />
              <div className={"cf-image-overlay"} />
            </div>
          ))
        }
        <Button
          style={{
            ...styles.pagingButton,
            ...styles.pagingButtonRight,
          }}
          onClick={() => setPage(((page+1) % imagesLen + imagesLen))}
        >
          <ChevronRight style={styles.pagingIcon} />
        </Button>
      </div>
      
      <Lightbox
        open={(lightboxIndex >= 0)}
        index={lightboxIndex}
        close={() => setLightboxIndex(-1)}
        slides={images}
        animation={{
          swipe: 200,
        }}
        plugins={[Thumbnails]}
      />

      {
        (!noOvButton) ? (
          <div className={"cf-gallery-ov-button-cont"}>
            <Button
              variant={"outlined"}
              color={"primary"}
              style={styles.ovButton}
              size={"large"}
              href={"https://shepstonegardens.our-venue.com/"}
              target={"_blank"}
            >
              Get an online quote
            </Button>
          </div>
        ) : null
      }
    </section>
  );
};

export default CoverFlowGallery;