import "./contactBlock.css";

import React from "react";

const ContactBlock = ({ head, contacts }) => {
  return (
    <div className={"contact-block"}>
      <h3>{head}</h3>
      {
        contacts.map((contact, index) => (
          <div
            className={"contact-block-contact"}
            key={index}
          >
            <p className={"contact-block-name"}>{contact.name}</p>
            <p className={"contact-block-number"}>{contact.number}</p>
            <p className={"contact-block-email"}>{contact.email}</p>
            <p className={"contact-block-budget"}>{contact.budget}</p>
            <p className={"contact-block-event"}>{contact.event}</p>
          </div>
        ))
      }
    </div>
  );
};

export default ContactBlock;

