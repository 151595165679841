import "./404.css";

import React from "react";
import { Button } from "@material-ui/core";
import { WhatsApp } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import Header from "../sections/Header";
import LineSeparator from "../sections/LineSeparator";


const styles = {
  contactButton: {
    width: "100%",
    maxWidth: "300px",
    height: "50px",
    marginBottom: "20px",
    textTransform: "none",
  },
  homeButton: {
    width: "100%",
    maxWidth: "300px",
    height: "50px",
    textTransform: "none",
  },
};

const Page404 = () => {
  return (
    <div>
      <Helmet>
        <title>The Shepstone Experience</title>
        <meta
          name={"robots"}
          content={"noindex"}
        ></meta>
      </Helmet>

      <Header
        bgImg={"/images/404_Header.jpg"}
        headText={"HELLO THERE!"}
      />

      {/* <LineSeparator noLine />
      <img
        src={"/images/404.png"}
        className={"img-404"}
      /> */}

      <LineSeparator noLine />
      <p className={"text-404"}>The good news: we've recently rebuilt our website! The bad news: it looks like you've accessed an old link... Not to worry, you can find the new site below:</p>
      <Link to={"/"}>
        <Button
          variant={"contained"}
          color={"primary"}
          style={styles.homeButton}
        >
          Enter the Shepstone Experience
        </Button>
      </Link>
      <LineSeparator noLine />

      <LineSeparator />
      <p className={"text-404"}>Or, feel free to send us a WhatsApp and we'll help you however we can!</p>
      <Button
        href={"https://wa.me/27678170890"}
        target={"_blank"}
        startIcon={<WhatsApp />}
        variant={"contained"}
        color={"secondary"}
        style={styles.contactButton}
      >
        Start chat
      </Button>
      
      <LineSeparator noLine />
    </div>
  );
};

export default Page404;

