/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/jsx-max-props-per-line */
import "./footer.css";

import React from "react";
import { Button } from "@material-ui/core";


const Footer = () => {
  return (
    <footer>
      <div className={"footer-socials"}>
        <Button
          href={"https://wa.me/27678170890"}
          target={"_blank"}
        >
          <img
            className={"footer-social-icon"}
            src={"/images/footer/whatsapp-logo.png"}
          />
        </Button>
        <Button
          href={"https://www.instagram.com/shepstone_gardens/"}
          target={"_blank"}
        >
          <img
            className={"footer-social-icon"}
            src={"/images/footer/instagram-logo.png"}
          />
        </Button>
        <Button
          href={"https://www.facebook.com/shepstone.gardens"}
          target={"_blank"}
        >
          <img
            className={"footer-social-icon"}
            src={"/images/footer/facebook-logo.png"}
          />
        </Button>
        <Button
          href={"https://www.youtube.com/watch?v=zYdguKA9a6E"}
          target={"_blank"}
        >
          <img
            className={"footer-social-icon"}
            src={"/images/footer/youtube-logo.png"}
          />
        </Button>
      </div>

      <p className={"footer-contact"}>
        {/* <a href={"mailto:info@shepstonegardens.co.za"}>info@shepstonegardens.co.za</a> */}
        <a href={"tel:0117286738"}>011 728 6738</a><br /><a href={"mailto:info@shepstonegardens.co.za"}>info@shepstonegardens.co.za</a>
      </p>

      <div className={"footer-badges"}>
        <Button
          href={"https://www.facebook.com/shepstone.gardens"}
          target={"_blank"}
        >
          <img
            className={"footer-badge-icon"}
            src={"/images/footer/footer_logo1.png"}
          />
        </Button>
        <Button
          href={"https://www.google.co.za/search?q=shepstone+gardens&oq=shep&aqs=chrome.4.69i60l2j69i57j69i60j69i59j35i39.3089j0j7&sourceid=chrome&ie=UTF-8#lrd=0x1e950db814ea8fbb:0x7d851f6cb0a67eb0,1,,,"}
          target={"_blank"}
        >
          <img
            className={"footer-badge-icon"}
            src={"/images/footer/footer_logo2.png"}
          />
        </Button>
        {/* <Button
          href={"https://our-venue.com/"}
          target={"_blank"}
        >
          <img
            className={"footer-badge-icon"}
            src={"/images/footer/footer_logo3.png"}
          />
        </Button>
        <Button
          href={"https://www.saweddings.co.za/"}
          target={"_blank"}
        >
          <img
            className={"footer-badge-icon"}
            src={"/images/footer/footer_logo4.png"}
          />
        </Button>
        <Button
          href={"https://olivelli.co.za/"}
          target={"_blank"}
        >
          <img
            className={"footer-badge-icon"}
            src={"/images/footer/footer_logo5.png"}
          />
        </Button>
        <Button
          href={"https://www.topreviews.co.za/best-wedding-venues-johannesburg/"}
          target={"_blank"}
        >
          <img
            className={"footer-badge-icon"}
            src={"/images/footer/footer_logo6.png"}
          />
        </Button> */}
      </div>

      <p className={"footer-legal"}>
        <a href={"/images/Shepstone Stand-Alone POPI Privacy Policy.pdf"} target={"_blank"}>Privacy policy</a> | Site built by <a href={"https://tap-x.com"} target={"_blank"}>TapX</a>
      </p>
    </footer>
  );
};

export default Footer;

