import "./maps.css";

import React from "react";


const Maps = ({ mapsList }) => {
  return (
    <div className={"maps-cont"}>
      {
        mapsList.map((map, i) => (
          <div
            className={"map-cont"}
            key={i}
          >
            <p>{map.name}</p>
            <iframe
              src={map.src}
              allowFullScreen
            />
          </div>
        ))
      }
    </div>
  );
};

export default Maps;