import "./blogPost.css";
import { Helmet } from "react-helmet";

import React from "react";
import { useParams } from "react-router-dom";

import blogPosts from "./blogData";
import Header from "../sections/Header";
import BlogPreview from "./BlogPreview";
import LineSeparator from "../sections/LineSeparator";
import BlockHeading from "../sections/BlockHeading";


const BlogPost = () => {
  const params = useParams();
  const postID = parseInt(params.postID);

  const post = blogPosts.find((post) => post.id === postID);
  const i = blogPosts.indexOf(post);
  let prevPost;
  let nextPost;

  if (i >= 1) {
    prevPost = blogPosts[i-1];
  }
  else {
    prevPost = blogPosts[i+2];
  }
  if (i < (blogPosts.length - 1)) {
    nextPost = blogPosts[i+1];
  }
  else {
    nextPost = blogPosts[i-2];
  }

  return (
    <div>
      <Helmet>
        <title>{post.head} | The Shepstone Experience</title>
        <meta
          name={"description"}
          content={post.previewText}
        ></meta>
      </Helmet>

      <Header
        bgImg={"/images/blog/blog-post-bg.jpg"}
        headText={post.head}
      />

      <section className={"blog-post-section standard-width"}>
        <p className={"blog-date-posted"}>Posted on {post.date}</p>
        <div className={"blog-post-content"}>
          {post.content}
        </div>
      </section>

      <section className={"standard-width"}>
        <LineSeparator />
        <BlockHeading
          headText={"More Stories"}
          noBorder
        />
        <div className={"blog-more-cont"}>
          <BlogPreview
            post={prevPost}
            isHalfWidth
          />
          <BlogPreview
            post={nextPost}
            isHalfWidth
          />
        </div>
        <LineSeparator noLine />
      </section>
    </div>
  );
};

export default BlogPost;

