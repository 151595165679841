import "./videoSection.css";

import React from "react";
import { IconButton } from "@material-ui/core";
import { PlayArrow } from "@material-ui/icons";

import LineSeparator from "./LineSeparator";

const styles = {
  playButton: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
    backgroundColor: "#C6D1C0",
    textTransform: "none",
  },
  playIcon: {
    width: 40,
    height: 40,
    color: "#fff",
    textTransform: "none",
  },
};


const VideoSection = ({ headText, previewImg, videoUrl, noLines=false }) => {
  const [showVideo, setShowVideo] = React.useState(false);

  return (
    <section className={"vid-section"}>
      <LineSeparator noLine={noLines} />
      <div className={"vid-cont standard-width"}>
        {
          (showVideo) ? (
            <iframe
              className={"vid-iframe"}
              src={videoUrl}
              frameBorder={"0"}
              allowFullScreen
            />
          ) : (
            <React.Fragment>
              <h3>{headText}</h3>
              <img
                className={"vid-preview-img"}
                src={previewImg}
              />
              <IconButton
                style={styles.playButton}
                onClick={() => setShowVideo(true)}
              >
                <PlayArrow style={styles.playIcon} />
              </IconButton>
            </React.Fragment>
          )
        }
        
      </div>
      <LineSeparator noLine={noLines} />
    </section>
  );
};

export default VideoSection;