import "./venues.css";

import React from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { PAGE_ROUTES } from "../../utils/stateTypes";
import Header from "../sections/Header";
import LineSeparator from "../sections/LineSeparator";
import BlockHeading from "../sections/BlockHeading";

const styles = {
  viewButton: {
    position: "absolute",
    top: "20%",
    left: "50%",
    transform: "translateX(-50%)",
    color: "#fff",
    borderColor: "#fff",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    width: 80,
    textTransform: "none",
  },
};


const Venues = () => {
  return (
    <div>
      <Helmet>
        <title>Our Venues | The Shepstone Experience</title>
        <meta
          name={"description"}
          content={"Discover the magical Shepstone Gardens and the awe-inspiring Great Hall."}
        ></meta>
      </Helmet>
    
      <Header
        bgImg={"/images/HEADER-2.jpg"}
        headText={"OUR VENUES"}
      />

      <section className={"venue-picker-section"}>

        <BlockHeading
          headText={"Choose one of our venues to see more"}
          noBorder
        />
        <LineSeparator />
        <LineSeparator noLine />

        <div className={"venue-picker-cont standard-width"}>
          <Link to={PAGE_ROUTES.VENUES_SHEP}>
            <div className={"venue-picker-item"}>
              <h3>Shepstone Gardens</h3>
              <img src={"/images/venue-picker-shepstone.jpg"} />
              <Button
                variant={"outlined"}
                style={styles.viewButton}
              >
                VIEW
              </Button>
            </div>
          </Link>
          <Link to={PAGE_ROUTES.VENUES_HALL}>
            <div className={"venue-picker-item"}>
              <h3>The Great Hall</h3>
              <img src={"/images/venue-picker-great-hall.jpg"} />
              <Button
                variant={"outlined"}
                style={styles.viewButton}
              >
                VIEW
              </Button>
            </div>
          </Link>
        </div>
      </section>

    </div>
  );
};

export default Venues;

