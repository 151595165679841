import "./testimonials.css";

import React from "react";
import { Dialog, DialogContent, DialogActions, Button, IconButton } from "@material-ui/core";
import { PlayArrow, ChevronLeft, ChevronRight } from "@material-ui/icons";

const styles = {
  diag: {
    padding: 0,
  },
  playButton: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
    border: "2px solid rgba(255, 255, 255, 0.5)",
    textTransform: "none",
  },
  playIcon: {
    width: 40,
    height: 40,
    textTransform: "none",
  },
  openButton: {
    marginTop: 10,
    textTransform: "none",
  },
  closeButton: {
    textTransform: "none",
  },
  pagingButton: {
    minWidth: 50,
    padding: 0,
    position: "absolute",
    height: "100%",
    top: 0,
    zIndex: 3,
    textTransform: "none",
  },
  pagingButtonLeft: {
    left: 0,
    textTransform: "none",
  },
  pagingButtonRight: {
    right: 0,
    textTransform: "none",
  },
  pagingIcon: {
    width: 45,
    height: 45,
    color: "#6F766B",
    textTransform: "none",
  },
};

const Testimonials = ({ testList }) => {
  const [open, setOpen] = React.useState(false);
  const [selectedTestimonial, setSelectedTestimonial] = React.useState({});
  const [showVideo, setShowVideo] = React.useState(false);

  const handleClickOpen = (testimonial) => {
    setSelectedTestimonial(testimonial);
    setShowVideo(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [page, setPage] = React.useState(0);
  const testsLen = testList.length;
  const renderedTests = [
    testList[((page-2) % testsLen + testsLen) % testsLen], //This is to circularly access the array from the active page
    testList[((page-1) % testsLen + testsLen) % testsLen],
    testList[((page) % testsLen + testsLen) % testsLen],
    testList[((page+1) % testsLen + testsLen) % testsLen],
    testList[((page+2) % testsLen + testsLen) % testsLen],
  ];

  return (
    <section className={"tests-section"}>
      {/* <div className={"tests-list-cont"}>
        {
          testList.map((test, i) => (
            <div
              key={i}
              className={["test-cont", (i === 1) ? "featured" : ""].join(" ")}
            >
              <img
                className={"test-open-quote"}
                src={"/images/quote.png"}
              />
              <p className={"test-text"}>{test.snippet}</p>
              <Button
                variant={"contained"}
                color={"secondary"}
                onClick={() => handleClickOpen(test)}
                style={styles.openButton}
              >
                Read more
              </Button>
            </div>
          ))
        }
      </div> */}

      <div className={"tests-list-cont standard-width"}>
        <Button
          style={{
            ...styles.pagingButton,
            ...styles.pagingButtonLeft,
          }}
          onClick={() => setPage(((page-1) % testsLen + testsLen))}
        >
          <ChevronLeft style={styles.pagingIcon} />
        </Button>
        {
          renderedTests.map((test, index) => (
            <div
              key={test.id}
              className={[
                "test-cont",
                `pos-${index}`,
              ].join(" ")}
            >
              <img
                className={"test-open-quote"}
                src={"/images/quote.png"}
              />
              <p className={"test-text"}>{test.snippet}</p>
              <Button
                variant={"contained"}
                color={"secondary"}
                onClick={() => handleClickOpen(test)}
                style={styles.openButton}
              >
                Read more
              </Button>
            </div>
          ))
        }
        <Button
          style={{
            ...styles.pagingButton,
            ...styles.pagingButtonRight,
          }}
          onClick={() => setPage(((page+1) % testsLen + testsLen))}
        >
          <ChevronRight style={styles.pagingIcon} />
        </Button>
      </div>

      <Dialog
        fullWidth
        maxWidth={"md"}
        open={open}
        onClose={handleClose}
        disableScrollLock
      >
        <DialogContent style={styles.diag}>
          <div className={"full-test-head-cont"}>
            {
              (showVideo) ? (
                <iframe
                  className={"full-test-head-video"}
                  src={selectedTestimonial.videoUrl}
                  frameBorder={"0"}
                  allowFullScreen
                />
              ) : (
                <React.Fragment>
                  <img
                    className={"full-test-head-img"}
                    src={selectedTestimonial.headImg}
                  />
                  {
                    (selectedTestimonial.videoUrl) ? (
                      <IconButton
                        style={styles.playButton}
                        color={"secondary"}
                        onClick={() => setShowVideo(true)}
                      >
                        <PlayArrow style={styles.playIcon} />
                      </IconButton>
                    ) : null
                  }
                </React.Fragment>
              )
            }
          </div>
          <div className={"full-test-text"}>
            {selectedTestimonial.fullText}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant={"outlined"}
            onClick={handleClose}
            color={"secondary"}
            style={styles.closeButton}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </section>
  );
};

export default Testimonials;