import React from "react";
import { Helmet } from "react-helmet";
import { Button } from "@material-ui/core";
import { WhatsApp } from "@material-ui/icons";
import { TextField } from "@material-ui/core";
// import { TextareaAutosize } from "@material-ui/core";
// import { Select } from "@material-ui/core";
// import { MenuItem } from "@material-ui/core";

import Header from "../sections/Header";
import LineSeparator from "../sections/LineSeparator";
import GreenBlockSection from "../sections/GreenBlockSection";

const styles = {
  buyButton: {
    width: "100%",
    maxWidth: "300px",
    height: "50px",
    textTransform: "none",
  },
  contactButton: {
    width: "100%",
    maxWidth: "300px",
    height: "50px",
    marginBottom: "20px",
    textTransform: "none",
  },
  homeButton: {
    width: "100%",
    maxWidth: "300px",
    height: "50px",
    textTransform: "none",
  },
  input: {
    marginBottom: 10,
    textTransform: "none",
    width: "200px",
  },
  textarea: {
    marginBottom: 10,
    textTransform: "none",
    width: "200px",
    height: "100px",
  },
};

const Shop = () => {
  const [quantity, setQuantity] = React.useState(1);
  const [purchaserName, setName] = React.useState(1);
  const [purchaserEmail, setEmail] = React.useState(1);
  const [purchaserCell, setCell] = React.useState(1);
  // const [dropDownSelection, setDropdown] = React.useState(1);
  // const [personalMessage, setMessage] = React.useState(1);
  // const [deliveryAddress, setAddress] = React.useState(1);
  return (
    <div>
      <Helmet>
        <title>Shop | The Shepstone Experience</title>
        <meta
          name={"description"}
          content={"Shop premium products and event tickets in our online store."}
        ></meta>
      </Helmet> 

      <Header
        bgImg={"/images/SG-Night-Open-Day-Header.jpg"}
        headText={"DATE NIGHT 2023 TICKETS"}
      />

      <LineSeparator noLine />
      <LineSeparator noLine />

      <GreenBlockSection
        headText={"Date Night 2023"}
        subHeadText={"The Shepstone Experience"}
      >
        <div className={"three-columns"}>
          <p className={"venue-intro-text"}>
          Don't miss the perfect opportunity to experience The Great Hall & Shepstone Gardens at night!
          </p>
          <p className={"venue-intro-text"}>
          Join us under the stars as we wine and dine together at Johannesburg's most exclusive venue.
          </p>
          <p className={"venue-intro-text"}>
          Spots are extrememly limited - so make sure you book online well in advance. See you there!
          </p>
        </div>
      </GreenBlockSection>
      <LineSeparator noLine />
      <LineSeparator noLine />

      <GreenBlockSection
        headText={"Ticket"}
        subHeadText={"Buy Your"}
      >
        <img
          src={"/images/SG-Night-Open-Day-1.jpg"}
          className={"banner-image"}
        />
        <br/><br/>
        <LineSeparator />
        <p className={"text-404"}>
          DETAILS:<br/><br/>
          - Price: R650 per couple<br/>
          - Date: Saturday, 30 September 2023<br/>
          - Time: 4pm till late
          <br/><br/>
          EACH TICKET INCLUDES:<br/><br/>
          - A delicious harvest plate to share<br/>
          - A glass of bubbles arrival<br/>
          - Live music<br/>
          - Cash bar available
          <br/><br/>
        </p>
        <LineSeparator />
        <br/>
        <p>
          TICKET PURCHASES HAVE OFFICIALLY CLOSED.
          <br/><br/>
          Thank you to all who booked for this special night!
          <br/><br/>
          See you there!
        </p>
        {/* <form
          name={"PayFastPayNowForm"}
          action={"https://www.payfast.co.za/eng/process"}
          method={"post"}
        >
          <input
            required
            type={"hidden"}
            name={"cmd"}
            value={"_paynow"}
          />
          <input
            required
            type={"hidden"}
            name={"receiver"}
            pattern={"[0-9]"}
            value={"15664450"}
          />
          <input
            type={"hidden"}
            name={"return_url"}
            value={"https://shepstonegardens.co.za/thankyou"}
          />
          <input
            type={"hidden"}
            name={"cancel_url"}
            value={"https://shepstonegardens.co.za/shop"}
          />
          <input
            type={"hidden"}
            name={"notify_url"}
            value={"https://shepstonegardens.co.za/api/Contact/Notify"}
          />
          <input
            required
            type={"hidden"}
            name={"amount"}
            value={650*quantity}
          />
          <table
            align={"center"}
          >
            <tr>
              <td>
                <label
                  htmlFor={"custom_quantity"}
                >
                  Quantity (1 ticket = 1 couple): 
                </label>
              </td>
              <td>
                <TextField
                  required 
                  id={"custom_quantity"}
                  type={"number"}
                  name={"custom_quantity"}
                  value={quantity}
                  min={"1"}
                  onChange={(e) => setQuantity(e.target.value)}
                  style={styles.input}
                />
              </td>
            </tr>
            <tr>
              <td>
                <label
                  htmlFor={"custom_name"}
                >
                  Your name: 
                </label>
              </td>
              <td>
                <TextField
                  required 
                  id={"custom_name"}
                  name={"custom_name"}
                  style={styles.input}
                  onChange={(e) => setName(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>
                <label
                  htmlFor={"email_address"}
                >
                  Your email: 
                </label>
              </td>
              <td>
                <TextField
                  required 
                  id={"email_address"}
                  name={"email_address"}
                  style={styles.input}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>
                <label
                  htmlFor={"custom_cell"}
                >
                  Your contact number: 
                </label>
              </td>
              <td>
                <TextField
                  required
                  id={"custom_cell"}
                  name={"custom_cell"}
                  style={styles.input}
                  onChange={(e) => setCell(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td
                colSpan={"2"}
                align={"center"}
              >
                <input
                  type={"image"}
                  src={"https://my.payfast.io/images/buttons/PayNow/Light-Large-PayNow.png"}
                  alt={"Pay Now"}
                  title={"Pay Now"}
                />
              </td>
            </tr>
          </table>
          <input
            required
            type={"hidden"}
            name={"item_name"}
            maxLength={"255"}
            value={"Date Night 2023 Ticket Purchase (Quantity: "+quantity+")"}
          />
          <input
            type={"hidden"}
            name={"item_description"}
            maxLength={"255"}
            value={"Purchaser details: "+purchaserName+", "+purchaserCell+", "+purchaserEmail}
          />
        </form> */}
      </GreenBlockSection>

      <LineSeparator noLine />
      <p className={"text-404"}>If you'd like to chat with us, feel free to send us a WhatsApp and we'll help you however we can!</p>
      <Button
        href={"https://wa.me/27678170890"}
        target={"_blank"}
        startIcon={<WhatsApp />}
        variant={"contained"}
        color={"secondary"}
        style={styles.contactButton}
      >
        Start chat
      </Button>
      
      <LineSeparator noLine />
    </div>
  );
};

export default Shop;

