import { PAGE_ROUTES, ENTRY_POINT } from "./stateTypes";

let entryPoint = ENTRY_POINT.DEFAULT;

export const loadEntryPoint = () => {
  if (entryPoint === ENTRY_POINT.DEFAULT) {
    let savedEntryPoint = localStorage.getItem("entryPoint");
    if (savedEntryPoint !== null) {
      entryPoint = parseInt(savedEntryPoint);
    }
  }
  else if (entryPoint === ENTRY_POINT.HOMECORPORATE) {
    let savedEntryPoint = localStorage.getItem("entryPoint");
    if (savedEntryPoint !== null) {
      entryPoint = parseInt(savedEntryPoint);
    }
  }
  else if (entryPoint === ENTRY_POINT.HOMEBDIAMOND) {
    let savedEntryPoint = localStorage.getItem("entryPoint");
    if (savedEntryPoint !== null) {
      entryPoint = parseInt(savedEntryPoint);
    }
  }
  else if (entryPoint === ENTRY_POINT.HOMELOVEISLOVE) {
    let savedEntryPoint = localStorage.getItem("entryPoint");
    if (savedEntryPoint !== null) {
      entryPoint = parseInt(savedEntryPoint);
    }
  }
};

export const saveEntryPoint = (pageUrl) => {
  //Only save it to storage if URL matches certain landing pages
  if (pageUrl === PAGE_ROUTES.HOME) {
    entryPoint = ENTRY_POINT.DEFAULT;
    localStorage.setItem("entryPoint", entryPoint);
  }
  else if (pageUrl === PAGE_ROUTES.HOMECORPORATE) {
    entryPoint = ENTRY_POINT.CORPORATE;
    localStorage.setItem("entryPoint", entryPoint);
  }
  else if (pageUrl === PAGE_ROUTES.HOMEBDIAMOND) {
    entryPoint = ENTRY_POINT.BLACK_DIAMOND;
    localStorage.setItem("entryPoint", entryPoint);
  }
  else if (pageUrl === PAGE_ROUTES.HOMELOVEISLOVE) {
    entryPoint = ENTRY_POINT.LOVE_IS_LOVE;
    localStorage.setItem("entryPoint", entryPoint);
  }
  else if (pageUrl === PAGE_ROUTES.HOMEBANNERADS) {
    entryPoint = ENTRY_POINT.BANNER_ADS;
    localStorage.setItem("entryPoint", entryPoint);
  }
};

export const getEntryPoint = () => (
  entryPoint
);