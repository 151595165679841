import "./greenBlockSection.css";

import React from "react";


const GreenBlockSection = ({ headText, subHeadText=null, children }) => {
  return (
    <section className={"green-block-section"}>
      <div className={"green-block standard-width"}>
        <div className={["green-block-head-cont", (subHeadText !== null) ? "with-sub-head" : ""].join(" ")}>
          {
            (subHeadText !== null) && (
              <p>{subHeadText}</p>
            )
          }
          <h3>{headText}</h3>
        </div>
        <div className={"green-block-inner"}>
          {children}
        </div>
      </div>
    </section>
  );
};

export default GreenBlockSection;