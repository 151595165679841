import "./galleryBlock.css";
import React from "react";

const GalleryBlock = ({ image, classOverride }) => (
  <div
    className={classOverride ? classOverride : "gallery-block"}
    style={{ backgroundImage: "url(/images/" + image + ")" }}
  />
);

export default GalleryBlock;