import "./galleryTab.css";

import React from "react";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";

const GalleryTab = ({ photos, isActive }) => {
  const [lightboxIndex, setLightboxIndex] = React.useState(-1);

  return (
    <div className={["tab-gallery-cont", (isActive) ? "active" : ""].join(" ")}>
      <PhotoAlbum
        layout={"rows"}
        photos={photos}
        spacing={10}
        onClick={(event, photo, index) => setLightboxIndex(index)}
      />

      <Lightbox
        open={(lightboxIndex >= 0)}
        index={lightboxIndex}
        close={() => setLightboxIndex(-1)}
        slides={photos}
        animation={{
          swipe: 200,
        }}
        plugins={[Thumbnails]}
      />
    </div>
  );
};

export default GalleryTab;