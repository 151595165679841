import "./blockHeading.css";

import React from "react";


const BlockHeading = ({ headText, isH1=false, isMaxWidth, noBorder=false, extraVerticalPadding=false }) => {
  const HeadTag = isH1 ? "h1" : "h2";
  return (
    <HeadTag className={[
      "block-heading",
      isMaxWidth ? "max-width" : "",
      noBorder ? "no-border" : "",
      extraVerticalPadding ? "extra-vertical-padding" : "",
    ].join(" ")}
    >{headText}</HeadTag>
  );
};

export default BlockHeading;