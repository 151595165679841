import "./blockGallery.css";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import React from "react";
import { Button } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";

const styles = {
  pagingButton: {
    minWidth: 50,
    padding: 0,
    textTransform: "none",
  },
  pagingIcon: {
    width: 45,
    height: 45,
    color: "#6F766B",
    textTransform: "none",
  },
  pagingIconDisabled: {
    width: 45,
    height: 45,
    color: "#ccc",
    textTransform: "none",
  },
};


const BlockGallery = ({ images }) => {
  const [page, setPage] = React.useState(0);
  const [lightboxIndex, setLightboxIndex] = React.useState(-1);

  let pagingButtons = [];
  for (let i = 0; i < Math.ceil(images.length / 4); i++) {
    pagingButtons.push(
      <button
        key={i}
        className={["paging-button", (page === i) ? "active" : ""].join(" ")}
        onClick={() => setPage(i)}
        style={{ transform: `scale(${Math.max(0.5, 1 - Math.abs(page - i) * 0.2)})` }}
      />,
    );
  }

  return (
    <section className={"block-gallery-section"}>
      <div className={"block-gallery-cont standard-width"}>
        {
          (images.length > 4) ? (
            <Button
              style={styles.pagingButton}
              disabled={(page === 0)}
              onClick={() => setPage(page - 1)}
            >
              <ChevronLeft style={(page === 0) ? styles.pagingIconDisabled : styles.pagingIcon} />
            </Button>
          ) : null
        }
        {
          images.slice(0, 4).map((startImage, index) => (
            <div
              key={index}
              className={"block-image-cont"}
            >
              {
                images.map((img, imgIndex) => (
                  ((imgIndex % 4) === index) ? (
                    <img
                      key={imgIndex}
                      className={[
                        "block-image",
                        ((imgIndex >= (page * 4)) && (imgIndex < ((page * 4) + 4))) ? "block-image-visible" : "block-image-hidden",
                      ].join(" ")}
                      src={img.src}
                      alt={img.alt}
                      style={{ transitionDelay: `${(imgIndex % 4) * 0.2}s` }}
                      onClick={() => setLightboxIndex(imgIndex)}
                    />
                  ) : null
                ))
              }
            </div>
          ))
        }
        {
          (images.length > 4) ? (
            <Button
              style={styles.pagingButton}
              disabled={(page === Math.ceil(images.length / 4) - 1)}
              onClick={() => setPage(page + 1)}
            >
              <ChevronRight style={(page === Math.ceil(images.length / 4) - 1) ? styles.pagingIconDisabled : styles.pagingIcon} />
            </Button>
          ) : null
        }
      </div>
      {
        (images.length > 4) ? (
          <div className={"block-gallery-paging-cont"}>
            {pagingButtons}
          </div>
        ) : null
      }
      
      <Lightbox
        open={(lightboxIndex >= 0)}
        index={lightboxIndex}
        close={() => setLightboxIndex(-1)}
        slides={images}
        animation={{
          swipe: 200,
        }}
        plugins={[Thumbnails]}
      />
    </section>
  );
};

export default BlockGallery;