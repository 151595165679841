import "./contact.css";

import React from "react";
import { TextField, CircularProgress, Button } from "@material-ui/core";
import { Helmet } from "react-helmet";

import { LOADING_STATE } from "../../utils/stateTypes";
import Header from "../sections/Header";
import LineSeparator from "../sections/LineSeparator";
import GreenBlockSection from "../sections/GreenBlockSection";
import Maps from "../sections/Maps";
import ContactBlock from "./ContactBlock";

const styles = {
  input: {
    marginBottom: 10,
    textTransform: "none",
  },
  submitButton: {
    width: 150,
    backgroundColor: "#fff",
    color: "#6F766B",
    marginTop: 10,
    textTransform: "none",
  },
};

const sendMessage = async (name, cell, email, budget, event, message, setSendState) => {
  setSendState(LOADING_STATE.LOADING);
  const response = await fetch("/api/Contact/SendContactMessage", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name,
      cell,
      email,
      budget,
      event,
      message,
    }),
  });
  if (response.ok) {
    setSendState(LOADING_STATE.LOADED);
  }
  else {
    setSendState(LOADING_STATE.ERROR);
  }
};


const Contact = () => {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [cell, setCell] = React.useState("");
  const [budget, setBudget] = React.useState("");
  const [event, setEvent] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [sendState, setSendState] = React.useState(LOADING_STATE.NOT_LOADING);

  let submitLabel = "Send";
  if (sendState === LOADING_STATE.LOADING) {
    submitLabel = <CircularProgress size={25} />;
  }
  else if (sendState === LOADING_STATE.LOADED) {
    submitLabel = "Sent!";
  }
  else if (sendState === LOADING_STATE.ERROR) {
    submitLabel = "Retry";
  }

  return (
    <div>
      <Helmet>
        <title>Contact | The Shepstone Experience</title>
        <meta
          name={"description"}
          content={"Get in touch, or come around for a visit!"}
        ></meta>
      </Helmet>
    
      <Header
        bgImg={"/images/contact-header.jpg"}
        headText={"LET'S START PLANNING"}
      />

      <section className={"contact-blocks-section"}>
        <div className={"contact-blocks standard-width"}>
          <ContactBlock
            head={"General"}
            contacts={[
              // {
              //   name: "Office",
              //   email: "info@shepstonegardens.co.za",
              // },
              {
                name: "Office",
                number: "011 728 6738",
                email: "info@shepstonegardens.co.za",
              },
            ]}
          />
          <ContactBlock
            head={"Sales"}
            contacts={[
              {
                name: "Becky",
                number: "079 563 7746",
                email: "becky@shepstonegardens.co.za",
              },
              {
                name: "Winston",
                number: "065 121 0099",
                email: "winston@shepstonegardens.co.za",
              },
              {
                name: "Kagiso",
                number: "066 310 2222",
                email: "kagi@shepstonegardens.co.za",
              },
            ]}
          />
          <ContactBlock
            head={"Coordinators"}
            contacts={[
              {
                name: "Jessica",
                email: "jess@shepstonegardens.co.za",
              },
              {
                name: "Sinead",
                email: "sinead@shepstonegardens.co.za",
              },
              {
                name: "Shannon",
                email: "shannon@shepstonegardens.co.za",
              },
            ]}
          />
          <ContactBlock
            head={"Flowers & Decor"}
            contacts={[
              {
                name: "Mira",
                email: "mira@shepstonegardens.co.za",
              },
              {
                name: "Babs",
                email: "babalwa@shepstonegardens.co.za",
              },
              {
                name: "Meg",
                number: "073 710 9111",
                email: "meg@shepstonegardens.co.za",
              },
            ]}
          />
        </div>
      </section>

      <form
        className={"message-form standard-width"}
        onSubmit={(e) => {
          e.preventDefault();
          sendMessage(name, cell, email, budget, event, message, setSendState);
        }}
      >
        <h3>Message us</h3>
        <div className={"message-form-inner"}>
          <TextField
            style={styles.input}
            variant={"outlined"}
            label={"Name"}
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        
          <TextField
            style={styles.input}
            variant={"outlined"}
            label={"Email"}
            type={"email"}
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        
          <TextField
            style={styles.input}
            variant={"outlined"}
            label={"Cell"}
            type={"tel"}
            fullWidth
            value={cell}
            onChange={(e) => setCell(e.target.value)}
          />

          <TextField
            style={styles.input}
            variant={"outlined"}
            label={"Budget"}
            fullWidth
            value={budget}
            onChange={(e) => setBudget(e.target.value)}
          />

          <TextField
            style={styles.input}
            variant={"outlined"}
            label={"Event type"}
            fullWidth
            value={event}
            onChange={(e) => setEvent(e.target.value)}
          />
        
          <TextField
            style={styles.input}
            variant={"outlined"}
            label={"Message"}
            multiline
            minRows={4}
            fullWidth
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />

          <Button
            variant={"contained"}
            style={styles.submitButton}
            color={"primary"}
            type={"submit"}
            disabled={(sendState === LOADING_STATE.LOADING) || (sendState === LOADING_STATE.LOADED)}
          >
            {submitLabel}
          </Button>
        </div>
      </form>

      <GreenBlockSection headText={"Where to find us"}>
        <Maps
          mapsList={[
            {
              name: "",
              src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3580.8688625250325!2d28.076721315029285!3d-26.168399983453803!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e950db814ea8fbb%3A0x7d851f6cb0a67eb0!2sShepstone+Gardens!5e0!3m2!1sen!2sza!4v1531310599383",
            },
          ]}
        />
      </GreenBlockSection>

      <LineSeparator noLine />
      
    </div>
  );
};

export default Contact;

