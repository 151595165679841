import "./header.css";
import "./sectionsShared.css";

import React from "react";
import BlockHeading from "./BlockHeading";


const Header = ({ bgImg, logo=null, headText=null, subSectionText=null, bgStyle=null, logoStyle=null }) => {
  return (
    <React.Fragment>
      <section className={"header-section"}>
        <img
          src={bgImg}
          className={"header-bg"}
          style={bgStyle}
        />
        {
          (logo !== null) ? (
            <img
              src={logo}
              className={"header-logo"}
              style={logoStyle}
            />
          ) : (
            <BlockHeading
              headText={headText}
              isH1
              isMaxWidth
              extraVerticalPadding
            />
          )
        }
        <div className={"header-line-to-bottom"} />
      </section>
      {
        (subSectionText !== null) ? (
          <p className={"header-sub-section"}>
            {subSectionText}
          </p>
        ) : null
      }
    </React.Fragment>
  );
};

export default Header;