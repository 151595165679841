import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";

import MuiTheme from "./muiTheme";
import App from "./components/App";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <MuiThemeProvider theme={MuiTheme}>
      <App />
    </MuiThemeProvider>
  </BrowserRouter>,
  rootElement,
);