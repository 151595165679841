import "./lineSeparator.css";

import React from "react";


const LineSeparator = ({ noLine=false }) => {
  return (
    <div className={["line-separator", (noLine) ? "invisible" : ""].join(" ")} />
  );
};

export default LineSeparator;