import "./home.css";
import "../contact/contact.css";

import React from "react";
import { TextField, CircularProgress, Button } from "@material-ui/core";
import { Helmet } from "react-helmet";

import { ENTRY_POINT } from "../../utils/stateTypes";
import { getEntryPoint } from "../../utils/entryStorage";
import { LOADING_STATE } from "../../utils/stateTypes";
import testimonialsData from "./testimonialsData";
import Header from "../sections/Header";
import BlockGallery from "../sections/BlockGallery";
import CoverFlowGallery from "../sections/CoverFlowGallery";
import LineSeparator from "../sections/LineSeparator";
import BlockHeading from "../sections/BlockHeading";
import Testimonials from "../sections/Testimonials";
import GreenBlockSection from "../sections/GreenBlockSection";
import VideoSection from "../sections/VideoSection";

const GALLERY_IMAGES_1 = {
  [ENTRY_POINT.DEFAULT]: [
    { src: "/images/block-gallery-1/SG-img-(2).jpg", alt: "A long table with flower center pieces in the Glass Marquee at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(7).jpg", alt: "Confetti being thrown on a young couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(18).jpg", alt: "An arched bouquet of flowers at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/Varene-Burton-(12).jpg", alt: "The famous Glass Marquee at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(39).jpg", alt: "A selection of various wedding flowers at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(44).jpg", alt: "A couple standing before a minister at an outdoor wedding ceremony at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(51).jpg", alt: "A close up of a beautiful dark-themed wedding at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(55).jpg", alt: "A beautiful sunny day at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(62).jpg", alt: "A floral arrangment in front of a stain-glass window at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(80).jpg", alt: "A couple saying 'I do' in front of family and friends at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(101).jpg", alt: "The sun shining onto the marble floors at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(114).jpg", alt: "An African bride smiling in front of a stain-glass window at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(134).jpg", alt: "The sun shining onto wedding table decorations at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(165).jpg", alt: "A bride in a black dress at her non-conventional wedding at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/Varene-Burton-(18).jpg", alt: "A rainy day under the Jacaranda trees at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(183).jpg", alt: "Black floral arrangements and decor at Shepstone Gardens & The Great Hall" },
  ],
  [ENTRY_POINT.BANNER_ADS]: [
    { src: "/images/block-gallery-1/SG-img-(2).jpg", alt: "A long table with flower center pieces in the Glass Marquee at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(7).jpg", alt: "Confetti being thrown on a young couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(18).jpg", alt: "An arched bouquet of flowers at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/Varene-Burton-(12).jpg", alt: "The famous Glass Marquee at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(39).jpg", alt: "A selection of various wedding flowers at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(44).jpg", alt: "A couple standing before a minister at an outdoor wedding ceremony at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(51).jpg", alt: "A close up of a beautiful dark-themed wedding at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(55).jpg", alt: "A beautiful sunny day at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(62).jpg", alt: "A floral arrangment in front of a stain-glass window at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(80).jpg", alt: "A couple saying 'I do' in front of family and friends at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(101).jpg", alt: "The sun shining onto the marble floors at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(114).jpg", alt: "An African bride smiling in front of a stain-glass window at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(134).jpg", alt: "The sun shining onto wedding table decorations at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(165).jpg", alt: "A bride in a black dress at her non-conventional wedding at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/Varene-Burton-(18).jpg", alt: "A rainy day under the Jacaranda trees at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(183).jpg", alt: "Black floral arrangements and decor at Shepstone Gardens & The Great Hall" },
  ],
};

const GALLERY_IMAGES_2 = {
  [ENTRY_POINT.DEFAULT]: [
    { src: "/images/block-gallery-1/Varene-Burton-(11).jpg", alt: "A newly married couple walking outdoors at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/Varene-Burton-(5).jpg", alt: "A beautiful modern bride standing in a old building at Shepstone Gardens & The Great Hall" },

    { src: "/images/block-gallery-1/SG-img-(43).jpg", alt: "A newly married couple walking outdoors at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(45).jpg", alt: "A beautiful modern bride standing in a old building at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(53).jpg", alt: "A golden staircase with fairylights and floral arrangements at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(60).jpg", alt: "A bouquet of roses on a tree branch at Shepstone Gardens & The Great Hall" },

    { src: "/images/block-gallery-1/Varene-Burton-(23).jpg", alt: "A newly married couple walking outdoors at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/Varene-Burton-(9).jpg", alt: "A beautiful modern bride standing in a old building at Shepstone Gardens & The Great Hall" },

    { src: "/images/block-gallery-1/SG-img-(63).jpg", alt: "A floral arrangement at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(102).jpg", alt: "A chapel aisle with marble floors at Shepstone Gardens & The Great Hall" },
  ],
  [ENTRY_POINT.BANNER_ADS]: [
    { src: "/images/block-gallery-1/Varene-Burton-(11).jpg", alt: "A newly married couple walking outdoors at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/Varene-Burton-(5).jpg", alt: "A beautiful modern bride standing in a old building at Shepstone Gardens & The Great Hall" },

    { src: "/images/block-gallery-1/SG-img-(43).jpg", alt: "A newly married couple walking outdoors at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(45).jpg", alt: "A beautiful modern bride standing in a old building at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(53).jpg", alt: "A golden staircase with fairylights and floral arrangements at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(60).jpg", alt: "A bouquet of roses on a tree branch at Shepstone Gardens & The Great Hall" },

    { src: "/images/block-gallery-1/Varene-Burton-(23).jpg", alt: "A newly married couple walking outdoors at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/Varene-Burton-(9).jpg", alt: "A beautiful modern bride standing in a old building at Shepstone Gardens & The Great Hall" },

    { src: "/images/block-gallery-1/SG-img-(63).jpg", alt: "A floral arrangement at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(102).jpg", alt: "A chapel aisle with marble floors at Shepstone Gardens & The Great Hall" },
  ],
};

const COVER_IMAGE = {
  [ENTRY_POINT.DEFAULT]: [
    "/images/home-header-3.jpg",
  ],
  [ENTRY_POINT.BANNER_ADS]: [
    "/images/home-header-3.jpg",
  ],
};

const WELCOME_MESSAGE = {
  [ENTRY_POINT.DEFAULT]: [
    "A venue for life's best occasions: the pinnacle of sophistication and grace...",
  ],
  [ENTRY_POINT.BANNER_ADS]: [
    "A venue for life's best occasions: the pinnacle of sophistication and grace...",
  ],
};

const styles = {
  input: {
    marginBottom: 10,
    textTransform: "none",
  },
  submitButton: {
    width: 150,
    backgroundColor: "#fff",
    color: "#6F766B",
    marginTop: 10,
    textTransform: "none",
  },
};

const sendMessage = async (name, cell, email, budget, event, message, setSendState) => {
  window.lmtracker = window.lmtracker || [];
  window.lmtracker.push(
    { event: "webtag_id", value: "o7uhy8AXXnEb" },
    { event: "eventName", value: "Submit button" },
    { event: "orderValue", value: "%ordervalue%" },
    { event: "sku", value: "%sku%" },
    { event: "custom_event_1", value: "%custom_macro_1%" },
  );
  setSendState(LOADING_STATE.LOADING);
  const response = await fetch("/api/Contact/SendContactMessageBannerAds", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name,
      cell,
      email,
      budget,
      event,
      message,
    }),
  });
  if (response.ok) {
    setSendState(LOADING_STATE.LOADED);
  }
  else {
    setSendState(LOADING_STATE.ERROR);
  }
};

const Home = () => {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [cell, setCell] = React.useState("");
  const [budget, setBudget] = React.useState("");
  const [event, setEvent] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [sendState, setSendState] = React.useState(LOADING_STATE.NOT_LOADING);

  let submitLabel = "Send";
  if (sendState === LOADING_STATE.LOADING) {
    submitLabel = <CircularProgress size={25} />;
  }
  else if (sendState === LOADING_STATE.LOADED) {
    submitLabel = "Sent!";
  }
  else if (sendState === LOADING_STATE.ERROR) {
    submitLabel = "Retry";
  }

  return (
    <div>
      <Helmet>
        <title>Contact | The Shepstone Experience</title>
        <meta
          name={"description"}
          content={"Get in touch, or come around for a visit!"}
        ></meta>
      </Helmet>
      <div className={"header-section-home-page"}>
        <Header
          bgImg={COVER_IMAGE[getEntryPoint()]}
          // logo={"/images/TSE-Logo-White2.png"}
          headText={"THE SHEPSTONE EXPERIENCE"}
        />
      </div>

      <LineSeparator noLine />
      <BlockHeading
        headText={WELCOME_MESSAGE[getEntryPoint()]}
        noBorder
      />
      <LineSeparator noLine />
      
      <BlockGallery
        images={GALLERY_IMAGES_1[getEntryPoint()]}
      />

      <LineSeparator noLine />

      <form
        className={"message-form standard-width"}
        onSubmit={(e) => {
          e.preventDefault();
          sendMessage(name, cell, email, budget, event, message, setSendState);
        }}
      >
        <br/>
        <h3>Contact us today to find out more!</h3>
        <br/>
        <div className={"message-form-inner"}>
          <TextField
            style={styles.input}
            variant={"outlined"}
            label={"Name"}
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        
          <TextField
            style={styles.input}
            variant={"outlined"}
            label={"Email"}
            type={"email"}
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        
          <TextField
            style={styles.input}
            variant={"outlined"}
            label={"Cell"}
            type={"tel"}
            fullWidth
            value={cell}
            onChange={(e) => setCell(e.target.value)}
          />

          <TextField
            style={styles.input}
            variant={"outlined"}
            label={"Budget"}
            fullWidth
            value={budget}
            onChange={(e) => setBudget(e.target.value)}
          />

          <TextField
            style={styles.input}
            variant={"outlined"}
            label={"Event type"}
            fullWidth
            value={event}
            onChange={(e) => setEvent(e.target.value)}
          />
        
          <TextField
            style={styles.input}
            variant={"outlined"}
            label={"Message"}
            multiline
            minRows={4}
            fullWidth
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />

          <Button
            variant={"contained"}
            style={styles.submitButton}
            color={"primary"}
            type={"submit"}
            disabled={(sendState === LOADING_STATE.LOADING) || (sendState === LOADING_STATE.LOADED)}
          >
            {submitLabel}
          </Button>
        </div>
      </form>
      
      <LineSeparator noLine />
      <LineSeparator noLine />

      <GreenBlockSection
        headText={"your unique event"}
        subHeadText={"Our venue,"}
      >
        <div className={"three-columns"}>
          <p className={"venue-intro-text"}>
            Defined by our careful attention to detail, service and coordination, The Shepstone Experience offers timelessly stylish and sophisticated venues, perfect for your wedding, event or corporate occasion. You have a beautiful story to tell, and we are here to help you write this chapter.
          </p>
          <p className={"venue-intro-text"}>
            Our incredible team of industry-leading professionals are here to ensure that your event is stress-free and flawless, with an in-house team that will take care of every single one of your unique needs and bring your dream day to life. No vision is too big for us to help you execute.
          </p>
          <p className={"venue-intro-text"}>
            Being a family-owned venue, Shepstone Gardens gives all of our wonderful guests a warm welcome and a service that is personalised. When you arrive at Shepstone Gardens, you become part of our family, and we are there to take care of you every step of the way.
          </p>
        </div>
      </GreenBlockSection>

      <LineSeparator noLine />
      <LineSeparator noLine />

      <VideoSection
        videoUrl={"https://www.youtube.com/embed/wvH9XEyYeB0?si=IKlYO0NjO0prXqO-"}
        headText={"Like no other..."}
        previewImg={"/images/Home-Page-Video-Tile.jpg"}
        noLines
      />

      <BlockHeading
        headText={"Reviews"}
        noBorder
      />
      <Testimonials testList={testimonialsData} />

      <CoverFlowGallery
        images={GALLERY_IMAGES_2[getEntryPoint()]}
        noOvButton
      />
    </div>
  );
};

export default Home;

