import "./gallery.css";

import React from "react";
import { Tabs, Tab } from "@material-ui/core";
import { Helmet } from "react-helmet";

import Header from "../sections/Header";
import LineSeparator from "../sections/LineSeparator";
import Contact from "../sections/Contact";
import GalleryTab from "./GalleryTab";

const styles = {
  tabs: {
    backgroundColor: "#fff",
    color: "#6F766B",
    textTransform: "none",
  },
  tab: {
    borderRight: "2px solid #C6D1C0",
    alignItems: "flex-start",
    textTransform: "none",
  },
  lastTab: {
    borderRight: "none",
    alignItems: "flex-start",
    textTransform: "none",
  },
};

const galleries = [
  {
    headText: "Ceremonies",
    headIcon: "/images/gallery/section-icon-ceremony.png",
    photos: [
      { src: "/images/gallery/Ceremony-(1).jpg", width: 1800, height: 1200 },
      { src: "/images/gallery/Ceremony-(2).jpg", width: 1800, height: 1198 },
      { src: "/images/gallery/Ceremony-(3).jpg", width: 1243, height: 1800 },
      { src: "/images/gallery/Ceremony-(4).jpg", width: 1209, height: 1800 },
      { src: "/images/gallery/Ceremony-(5).jpg", width: 1200, height: 1800 },
      { src: "/images/gallery/Ceremony-(6).jpg", width: 1800, height: 1200 },
      { src: "/images/gallery/Ceremony-(7).jpg", width: 1800, height: 1200 },
      { src: "/images/gallery/Ceremony-(8).jpg", width: 1800, height: 1200 },
      { src: "/images/gallery/Ceremony-(9).jpg", width: 1198, height: 1800 },
      { src: "/images/gallery/Ceremony-(10).jpg", width: 1800, height: 1200 },
      { src: "/images/gallery/Ceremony-(11).jpg", width: 1200, height: 1800 },
      { src: "/images/gallery/Ceremony-(12).jpg", width: 1800, height: 1200 },
      { src: "/images/gallery/Ceremony-(13).jpg", width: 1800, height: 1188 },
      { src: "/images/gallery/Ceremony-(14).jpg", width: 1200, height: 1800 },
      { src: "/images/gallery/Ceremony-(15).jpg", width: 1800, height: 1202 },
      { src: "/images/gallery/Ceremony-(16).jpg", width: 1202, height: 1800 },
      { src: "/images/gallery/Ceremony-(17).jpg", width: 1202, height: 1800 },
      { src: "/images/gallery/SG-img-(3).jpg", width: 1920, height: 1281 },
      { src: "/images/gallery/SG-img-(7).jpg", width: 1290, height: 1920 },
      { src: "/images/gallery/SG-img-(11).jpg", width: 1920, height: 1280 },
      { src: "/images/gallery/SG-img-(12).jpg", width: 1920, height: 1280 },
      { src: "/images/gallery/SG-img-(13).jpg", width: 1920, height: 1280 },
      { src: "/images/gallery/SG-img-(15).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(16).jpg", width: 1920, height: 1285 },
      { src: "/images/gallery/SG-img-(19).jpg", width: 1920, height: 1202 },
      { src: "/images/gallery/SG-img-(37).jpg", width: 1393, height: 1920 },
      { src: "/images/gallery/SG-img-(42).jpg", width: 1920, height: 1281 },
      { src: "/images/gallery/SG-img-(43).jpg", width: 1282, height: 1920 },
      { src: "/images/gallery/SG-img-(44).jpg", width: 1920, height: 1280 },
      { src: "/images/gallery/SG-img-(55).jpg", width: 1920, height: 1224 },
      { src: "/images/gallery/SG-img-(58).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(59).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(67).jpg", width: 1301, height: 1920 },
      { src: "/images/gallery/SG-img-(68).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(80).jpg", width: 1326, height: 1920 },
      { src: "/images/gallery/SG-img-(81).jpg", width: 1920, height: 1280 },
      { src: "/images/gallery/SG-img-(82).jpg", width: 1920, height: 1280 },
      { src: "/images/gallery/SG-img-(101).jpg", width: 1326, height: 1920 },
      { src: "/images/gallery/SG-img-(102).jpg", width: 1333, height: 1920 },
      { src: "/images/gallery/SG-img-(104).jpg", width: 1920, height: 1280 },
      { src: "/images/gallery/SG-img-(122).jpg", width: 1281, height: 1920 },
      { src: "/images/gallery/SG-img-(150).jpg", width: 1281, height: 1920 },
      { src: "/images/gallery/SG-img-(156).jpg", width: 1920, height: 1280 },
      { src: "/images/gallery/SG-img-(172).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(173).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(188).jpg", width: 1920, height: 1287 },
      { src: "/images/gallery/April-2024-Ceremony-1.jpg", width: 800, height: 1200 },
      { src: "/images/gallery/April-2024-Ceremony-2.jpg", width: 800, height: 1200 },
    ],
  },
  {
    headText: "Receptions",
    headIcon: "/images/gallery/section-icon-reception.png",
    photos: [
      { src: "/images/gallery/Reception-(1).jpg", width: 1800, height: 1200 },
      { src: "/images/gallery/Reception-(2).jpg", width: 1800, height: 1200 },
      { src: "/images/gallery/Reception-(3).jpg", width: 1800, height: 1167 },
      { src: "/images/gallery/Reception-(4).jpg", width: 1201, height: 1800 },
      { src: "/images/gallery/Reception-(5).jpg", width: 1800, height: 1166 },
      { src: "/images/gallery/Reception-(6).jpg", width: 1800, height: 1200 },
      { src: "/images/gallery/Reception-(7).jpg", width: 1200, height: 1800 },
      { src: "/images/gallery/Reception-(8).jpg", width: 1201, height: 1800 },
      { src: "/images/gallery/Reception-(9).jpg", width: 1800, height: 1200 },
      { src: "/images/gallery/Reception-(10).jpg", width: 1200, height: 1800 },
      { src: "/images/gallery/Reception-(11).jpg", width: 1800, height: 1200 },
      { src: "/images/gallery/Reception-(12).jpg", width: 1293, height: 1800 },
      { src: "/images/gallery/Reception-(13).jpg", width: 1800, height: 1209 },
      { src: "/images/gallery/Reception-(14).jpg", width: 1800, height: 1200 },
      { src: "/images/gallery/SG-img-(1).jpg", width: 1275, height: 1920 },
      { src: "/images/gallery/SG-img-(5).jpg", width: 1920, height: 1281 },
      { src: "/images/gallery/SG-img-(14).jpg", width: 1920, height: 1280 },
      { src: "/images/gallery/SG-img-(20).jpg", width: 1920, height: 1920 },
      { src: "/images/gallery/SG-img-(21).jpg", width: 1920, height: 1920 },
      { src: "/images/gallery/SG-img-(22).jpg", width: 1920, height: 1920 },
      { src: "/images/gallery/SG-img-(24).jpg", width: 1920, height: 1280 },
      { src: "/images/gallery/SG-img-(26).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(27).jpg", width: 1920, height: 1281 },
      { src: "/images/gallery/SG-img-(28).jpg", width: 1920, height: 1237 },
      { src: "/images/gallery/SG-img-(29).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(30).jpg", width: 1334, height: 1920 },
      { src: "/images/gallery/SG-img-(32).jpg", width: 1920, height: 1280 },
      { src: "/images/gallery/SG-img-(35).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(36).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(49).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(50).jpg", width: 1920, height: 1281 },
      { src: "/images/gallery/SG-img-(54).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(64).jpg", width: 1358, height: 1920 },
      { src: "/images/gallery/SG-img-(65).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(72).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(73).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(76).jpg", width: 1329, height: 1920 },
      { src: "/images/gallery/SG-img-(83).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(87).jpg", width: 1920, height: 1247 },
      { src: "/images/gallery/SG-img-(88).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(89).jpg", width: 1920, height: 1280 },
      { src: "/images/gallery/SG-img-(90).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(92).jpg", width: 1920, height: 1280 },
      { src: "/images/gallery/SG-img-(94).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(96).jpg", width: 1920, height: 1280 },
      { src: "/images/gallery/SG-img-(97).jpg", width: 1920, height: 1280 },
      { src: "/images/gallery/SG-img-(98).jpg", width: 1920, height: 1280 },
      { src: "/images/gallery/SG-img-(99).jpg", width: 1920, height: 1252 },
      { src: "/images/gallery/SG-img-(105).jpg", width: 1920, height: 1262 },
      { src: "/images/gallery/SG-img-(106).jpg", width: 1920, height: 1246 },
      { src: "/images/gallery/SG-img-(108).jpg", width: 1920, height: 1280 },
      { src: "/images/gallery/SG-img-(118).jpg", width: 1920, height: 1282 },
      { src: "/images/gallery/SG-img-(142).jpg", width: 1283, height: 1920 },
      { src: "/images/gallery/SG-img-(145).jpg", width: 1333, height: 1920 },
      { src: "/images/gallery/SG-img-(170).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(174).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(175).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(181).jpg", width: 1920, height: 1920 },
      { src: "/images/gallery/SG-img-(185).jpg", width: 1282, height: 1920 },
      { src: "/images/gallery/SG-img-(196).jpg", width: 1920, height: 1280 },
      { src: "/images/gallery/SG-img-(197).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(198).jpg", width: 1920, height: 1280 },
      { src: "/images/gallery/SG-img-(199).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(200).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(201).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(202).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(205).jpg", width: 1920, height: 1280 },
      { src: "/images/gallery/April-2024-Reception-1.jpg", width: 1920, height: 1280 },
      { src: "/images/gallery/April-2024-Reception-2.jpg", width: 1920, height: 1280 },
    ],
  },
  {
    headText: "Flowers & Decor",
    headIcon: "/images/gallery/section-icon-party.png",
    photos: [
      { src: "/images/gallery/Florals-(1).jpg", width: 1200, height: 1800 },
      { src: "/images/gallery/Florals-(2).jpg", width: 1200, height: 1800 },
      { src: "/images/gallery/Florals-(3).jpg", width: 1800, height: 1200 },
      { src: "/images/gallery/Florals-(4).jpg", width: 1247, height: 1800 },
      { src: "/images/gallery/Florals-(5).jpg", width: 1200, height: 1800 },
      { src: "/images/gallery/Florals-(6).jpg", width: 1200, height: 1800 },
      { src: "/images/gallery/Florals-(7).jpg", width: 1200, height: 1800 },
      { src: "/images/gallery/Florals-(8).jpg", width: 1201, height: 1800 },
      { src: "/images/gallery/Florals-(9).jpg", width: 1202, height: 1800 },
      { src: "/images/gallery/Florals-(10).jpg", width: 1800, height: 1167 },
      { src: "/images/gallery/Florals-(11).jpg", width: 1220, height: 1800 },
      { src: "/images/gallery/Florals-(12).jpg", width: 1800, height: 1175 },
      { src: "/images/gallery/Florals-(13).jpg", width: 1800, height: 1196 },
      { src: "/images/gallery/Florals-(14).jpg", width: 1200, height: 1800 },
      { src: "/images/gallery/Florals-(15).jpg", width: 1195, height: 1800 },
      { src: "/images/gallery/Florals-(16).jpg", width: 1200, height: 1800 },
      { src: "/images/gallery/SG-img-(2).jpg", width: 1281, height: 1920 },
      { src: "/images/gallery/SG-img-(4).jpg", width: 1281, height: 1920 },
      { src: "/images/gallery/SG-img-(6).jpg", width: 1920, height: 1920 },
      { src: "/images/gallery/SG-img-(8).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(9).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(10).jpg", width: 1920, height: 1280 },
      { src: "/images/gallery/SG-img-(23).jpg", width: 1920, height: 1920 },
      { src: "/images/gallery/SG-img-(25).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(31).jpg", width: 1920, height: 1254 },
      { src: "/images/gallery/SG-img-(33).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(34).jpg", width: 1920, height: 1280 },
      { src: "/images/gallery/SG-img-(38).jpg", width: 1281, height: 1920 },
      { src: "/images/gallery/SG-img-(39).jpg", width: 1920, height: 1920 },
      { src: "/images/gallery/SG-img-(40).jpg", width: 1281, height: 1920 },
      { src: "/images/gallery/SG-img-(41).jpg", width: 1281, height: 1920 },
      { src: "/images/gallery/SG-img-(52).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(53).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(56).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(57).jpg", width: 1284, height: 1920 },
      { src: "/images/gallery/SG-img-(60).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(61).jpg", width: 1340, height: 1920 },
      { src: "/images/gallery/SG-img-(62).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(63).jpg", width: 1275, height: 1920 },
      { src: "/images/gallery/SG-img-(66).jpg", width: 1330, height: 1920 },
      { src: "/images/gallery/SG-img-(69).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(70).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(71).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(74).jpg", width: 1920, height: 1249 },
      { src: "/images/gallery/SG-img-(75).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(111).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(112).jpg", width: 1281, height: 1920 },
      { src: "/images/gallery/SG-img-(117).jpg", width: 1920, height: 1920 },
      { src: "/images/gallery/SG-img-(123).jpg", width: 1920, height: 1286 },
      { src: "/images/gallery/SG-img-(133).jpg", width: 1296, height: 1920 },
      { src: "/images/gallery/SG-img-(136).jpg", width: 1283, height: 1920 },
      { src: "/images/gallery/SG-img-(157).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(159).jpg", width: 1279, height: 1920 },
      { src: "/images/gallery/SG-img-(160).jpg", width: 1279, height: 1920 },
      { src: "/images/gallery/SG-img-(161).jpg", width: 1279, height: 1920 },
      { src: "/images/gallery/SG-img-(162).jpg", width: 1279, height: 1920 },
      { src: "/images/gallery/SG-img-(182).jpg", width: 1920, height: 1920 },
      { src: "/images/gallery/SG-img-(183).jpg", width: 1920, height: 1920 },
      { src: "/images/gallery/SG-img-(184).jpg", width: 1920, height: 1920 },
      { src: "/images/gallery/SG-img-(189).jpg", width: 1282, height: 1920 },
      { src: "/images/gallery/SG-img-(190).jpg", width: 1282, height: 1920 },
      { src: "/images/gallery/SG-img-(191).jpg", width: 1282, height: 1920 },
      { src: "/images/gallery/SG-img-(193).jpg", width: 1282, height: 1920 },
      { src: "/images/gallery/SG-img-(194).jpg", width: 1920, height: 1282 },
      { src: "/images/gallery/SG-img-(203).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(204).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(206).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(207).jpg", width: 1920, height: 1280 },
      { src: "/images/gallery/SG-img-(208).jpg", width: 1920, height: 1280 },
      { src: "/images/gallery/SG-img-(209).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(210).jpg", width: 1920, height: 1280 },
      { src: "/images/gallery/SG-img-(211).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(212).jpg", width: 1280, height: 1920 },
      { src: "/images/gallery/SG-img-(213).jpg", width: 1920, height: 1280 },
      { src: "/images/gallery/April-2024-Flowers-1.jpg", width: 800, height: 1200 },
      { src: "/images/gallery/April-2024-Flowers-2.jpg", width: 800, height: 1200 },
    ],
  },
  {
    headText: "Dancefloor",
    headIcon: "/images/gallery/section-icon-dancefloor.png",
    photos: [
      { src: "/images/gallery/Dancefloor-(1).jpg", width: 1200, height: 1800 },
      { src: "/images/gallery/Dancefloor-(2).jpg", width: 1800, height: 1198 },
      { src: "/images/gallery/Dancefloor-(3).jpg", width: 1800, height: 1195 },
      { src: "/images/gallery/Dancefloor-(4).jpg", width: 1800, height: 1200 },
      { src: "/images/gallery/Dancefloor-(5).jpg", width: 1200, height: 1800 },
      { src: "/images/gallery/Dancefloor-(6).jpg", width: 1790, height: 1191 },
      { src: "/images/gallery/Dancefloor-(7).jpg", width: 1788, height: 1186 },
      { src: "/images/gallery/Dancefloor-(8).jpg", width: 1800, height: 1200 },
      { src: "/images/gallery/April-2024-Dancefloor-1.jpg", width: 1920, height: 1280 },
    ],
  },
  {
    headText: "Photo Opportunities",
    headIcon: "/images/gallery/section-icon-camera.png",
    photos: [
      { src: "/images/gallery/Photo_Ops-(1).1.jpg", width: 1200, height: 1800 },
      { src: "/images/gallery/Photo_Ops-(1).jpg", width: 1200, height: 1800 },
      { src: "/images/gallery/Photo_Ops-(2).1.jpg", width: 1800, height: 1201 },
      { src: "/images/gallery/Photo_Ops-(2).jpg", width: 1200, height: 1800 },
      { src: "/images/gallery/Photo_Ops-(3).1.jpg", width: 1200, height: 1800 },
      { src: "/images/gallery/Photo_Ops-(3).jpg", width: 1200, height: 1800 },
      { src: "/images/gallery/Photo_Ops-(4).1.jpg", width: 1800, height: 1200 },
      { src: "/images/gallery/Photo_Ops-(4).jpg", width: 1201, height: 1800 },
      { src: "/images/gallery/Photo_Ops-(5).jpg", width: 1800, height: 1800 },
      { src: "/images/gallery/Photo_Ops-(6).jpg", width: 1800, height: 1200 },
      { src: "/images/gallery/Photo_Ops-(7).jpg", width: 1200, height: 1800 },
      { src: "/images/gallery/Photo_Ops-(8).jpg", width: 1800, height: 1200 },
      { src: "/images/gallery/Photo_Ops-(9).jpg", width: 1270, height: 1800 },
      { src: "/images/gallery/Photo_Ops-(10).jpg", width: 1800, height: 1200 },
      { src: "/images/gallery/Photo_Ops-(11).jpg", width: 1200, height: 1800 },
      { src: "/images/gallery/Photo_Ops-(12).jpg", width: 1800, height: 1200 },
      { src: "/images/gallery/Photo_Ops-(13).jpg", width: 1800, height: 1200 },
      { src: "/images/gallery/Photo_Ops-(14).jpg", width: 1800, height: 1200 },
      { src: "/images/gallery/Photo_Ops-(15).jpg", width: 1800, height: 1148 },
      { src: "/images/gallery/Photo_Ops-(16).jpg", width: 1800, height: 1214 },
      { src: "/images/gallery/Photo_Ops-(17).jpg", width: 1800, height: 1200 },
      { src: "/images/gallery/Photo_Ops-(18).jpg", width: 1200, height: 1800 },
      { src: "/images/gallery/Photo_Ops-(19).jpg", width: 1200, height: 1800 },
      { src: "/images/gallery/Photo_Ops-(20).jpg", width: 1200, height: 1800 },
      { src: "/images/gallery/Photo_Ops-(21).jpg", width: 1800, height: 1200 },
      { src: "/images/gallery/Photo_Ops-(22).jpg", width: 1202, height: 1800 },
      { src: "/images/gallery/Photo_Ops-(23).jpg", width: 1202, height: 1800 },
      { src: "/images/gallery/Photo_Ops-(24).jpg", width: 1800, height: 1202 },
      { src: "/images/gallery/April-2024-Photos-1.jpg", width: 800, height: 1200 },
      { src: "/images/gallery/April-2024-Photos-2.jpg", width: 800, height: 1200 },
    ],
  },
];


const Gallery = () => {

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Helmet>
        <title>Gallery | The Shepstone Experience</title>
        <meta
          name={"description"}
          content={"Get an idea of how your dream can be a reality at Shepstone Gardens & The Great Hall with these amazing photos."}
        ></meta>
      </Helmet>

      <Header
        bgImg={"/images/gallery-header.jpg"}
        headText={"GALLERY"}
      />

      <section className={"gallery-section"}>
        <div className={"gal-outer-cont standard-width"}>
          <Tabs
            value={value}
            onChange={handleChange}
            style={styles.tabs}
            indicatorColor={"secondary"}
            variant={"fullWidth"}
            centered
          >
            {
              galleries.map((gallery, index) => (
                <Tab
                  key={index}
                  style={(index === (galleries.length - 1)) ? styles.lastTab : styles.tab}
                  label={
                    <React.Fragment>
                      <div className={"team-head-icon-cont"}>
                        <img
                          className={"team-head-icon"}
                          src={gallery.headIcon}
                        />
                      </div>
                      {gallery.headText}
                    </React.Fragment>
                  }
                />
              ))
            }
          </Tabs>
        
          {
            galleries.map((gallery, index) => (
              <GalleryTab
                key={index}
                photos={gallery.photos}
                isActive={(value === index)}
              />
            ))
          }
        </div>
      </section>

      <LineSeparator noLine />

      <LineSeparator />
      <Contact />
    </div>
  );
};

export default Gallery;