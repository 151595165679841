import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#6F766B",
      contrastText: "#fff",
    },
    secondary: {
      main: "#C6D1C0",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Raleway",
    allVariants: {
      "@media (max-width:1200px)": {
        fontSize: "0.9em",
      },
      "@media (max-width:800px)": {
        fontSize: "0.8em",
      },
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 0,
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: "#6F766B",
        color: "#fff",
      },
    },
    MuiDialogActions: {
      root: {
        backgroundColor: "rgba(255, 255, 255, 0.05)",
      },
    },
    MuiInputLabel: {
      root: {
        color: "#fff",
        zIndex: 1,
        "&$focused": {
          color: "#fff",
        },
      },
      shrink: {
        color: "#fff",
      },
      filled: {
        color: "#fff",
      },
    },
    MuiOutlinedInput: {
      input: {
        color: "#fff",
      },
      notchedOutline: {
        borderColor: "#fff !important",
      },
    },
    MuiTabs: {
      indicator: {
        height: 0,
      },
    },
    MuiTab: {
      textColorInherit: {
        opacity: 1,
      },
      root: {
        "&$selected": {
          backgroundColor: "#f0f1ef",
        },
      },
    },
    MuiSelect: {
      icon: {
        color: "#fff",
      },
    },
  },
});

export default theme;