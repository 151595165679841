import "./blogPreview.css";

import React from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

const styles = {
  readMoreButton: {
    position: "absolute",
    bottom: 10,
    right: 10,
    color: "#fff",
    borderColor: "#fff",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    width: 80,
    textTransform: "none",
  },
};


const BlogPreview = ({ post, isFeatured, isHalfWidth=false }) => {
  return (
    <div className={["blog-preview", (isFeatured) ? "featured" : "", (isHalfWidth) ? "half" : ""].join(" ")}>
      <img
        className={"blog-preview-img"}
        src={post.previewImage}
      />
      <Link
        className={"blog-preview-content"}
        to={`/Blog-post/${post.id}`}
      >
        <h3 className={"blog-preview-head"}>{post.head}</h3>
        <p className={"blog-preview-text"}>{post.previewText}</p>
        <p className={"blog-preview-date"}>{post.date}</p>
      </Link>
      <Link to={`/Blog-post/${post.id}`}>
        <Button
          variant={"outlined"}
          style={styles.readMoreButton}
        >
          READ
        </Button>
      </Link>
    </div>
  );
};

export default BlogPreview;

